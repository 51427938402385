import React, { useEffect, useMemo, useRef } from "react";
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { Helmet } from "react-helmet-async";
import { SkeletonTheme } from "react-loading-skeleton";
import { IntlProvider } from "react-intl";
import { CookieConsentProvider } from "@use-cookie-consent/react";
import { useAppConfig } from "@ct-react/core";
import { defaultLocale, useLocaleContext } from "@ct-react/locale";
import { getIntlMessages } from "./i18n";
import LocaleRoutes from "./routes";
import { RunningErrorUi } from "./components/layout/error-boundary";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import { CookieProvider } from "./code-splitting/client-only-ui";
import "../scss/global.scss";

const cookieConsentOptions = { consentCookieAttributes: { expires: 180 } };

const App = () => {

  const { locale } = useLocaleContext();
  const { options: { withInsights } } = useAppConfig();

  const insightsReactPlugin = useRef(new ReactPlugin());
  const insights = useRef(!withInsights? undefined : new ApplicationInsights({
    config: {
      connectionString: withInsights,
      extensions: [ insightsReactPlugin.current ],
      enableAutoRouteTracking: true,
      excludeRequestFromAutoTrackingPatterns: [ /azure\.com/, /google\.com/ ]
    }
  }));

  useEffect(() => {
    insights.current?.loadAppInsights();
    insights.current?.addTelemetryInitializer((env: ITelemetryItem) => {
      env.tags = env.tags || {};
      env.tags["ai.cloud.role"] = "webapp-rentalp";
    });
  }, []);

  const appRendering = useMemo(() => (
    <IntlProvider locale={locale.code}
                  defaultLocale={defaultLocale.code}
                  messages={getIntlMessages(locale)}>
      <CookieConsentProvider useCookieConsentHooksOptions={cookieConsentOptions}>
        <Helmet>
          <html lang={locale.code} />
          <meta property="og:locale" content={locale.code} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="rentalp.com" />
        </Helmet>
        <CookieProvider />
        <SkeletonTheme baseColor="var(--ctr-skeleton-bg)" highlightColor="var(--ctr-skeleton-hl)">
          <Header />
          <div className="main-wrap">
            {!withInsights
              ? <LocaleRoutes />
              : <AppInsightsErrorBoundary appInsights={insightsReactPlugin.current}
                                          onError={() => <RunningErrorUi />}>
                <LocaleRoutes />
              </AppInsightsErrorBoundary>
            }
          </div>
          <Footer />
        </SkeletonTheme>
      </CookieConsentProvider>
    </IntlProvider>
  ), [ locale ]);

  if (!withInsights)
    return appRendering;

  return (
    <AppInsightsContext.Provider value={insightsReactPlugin.current}>
      {appRendering}
    </AppInsightsContext.Provider>);

};

export default App;
