import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs
} from "@floating-ui/react";
import { classNames, useIsMounted } from "@ct-react/core";
import { IconMinus, IconPlus, IconUser, IconX } from "@tabler/icons-react";
import {
  MainSearchInputProps,
  SearchInputDefinitionProps,
  SearchInputSelectionProps,
  SearchInputViewProps
} from "./common";
import "./common.scss";
import "./guests-input.scss";

const transDefs = defineMessages({
  label: { id: "search-form-guest-input-label", defaultMessage: "Nombre" },
  placeholder: { id: "search-form-guests-placeholder", defaultMessage: "Ajouter des personnes" },
  state: { id: "search-form-guests-state", defaultMessage: "{format, select, single {{guests} personne} max {{max}+ personnes} other {{guests} personnes}}" },
  adults: { id: "search-form-guest-adults", defaultMessage: "Adultes" },
  children: { id: "search-form-guest-children", defaultMessage: "Enfants" }
});

const maxDefault = 10;

export const guestsFormattedState = (intl: IntlShape, val: number | undefined, maxDef?: number) => {
  if (!val || val < 1) return intl.formatMessage(transDefs.placeholder);
  const max = maxDef || maxDefault;
  return intl.formatMessage(transDefs.state, {
    guests: val ?? 0,
    max,
    format: val >= max! ? "max" : (val === 1 ? "single" : "other")
  });
}

type InputExtraProps = { max?: number };
type SplittedValue = { adults: number, children: number };
type InputViewProps = SearchInputDefinitionProps & InputExtraProps & SearchInputViewProps<SplittedValue>;
type InputSelectionProps = InputExtraProps & SearchInputSelectionProps<SplittedValue>;
type MainSearchGuestsInputProps = MainSearchInputProps<number> & InputExtraProps;

const InputSelection = (
  {
    value,
    max,
    onSelection
  }: InputSelectionProps) => {

  const intl = useIntl();
  const count = !!value ? value.adults + value.children : 0;

  const isStepBtnDisabled = (direction: 1 | -1, forAdults: boolean = true) => {
    if (direction === 1 && count === max) return true;
    if (direction === -1 && (!value || count === 0)) return true;
    if (direction === -1) return forAdults ? value!.adults === 0 : value!.children === 0;
    return false;
  }

  const onClick = (e: MouseEvent, type: "adults" | "children", direction: 1 | -1) => {
    e.stopPropagation();
    type === "adults"
      ? onSelection({ adults: (value?.adults || 0) + direction, children: (value?.children || 0) })
      : onSelection({ adults: (value?.adults || 0), children: (value?.children || 0) + direction });
  }

  return (
    <div className="guests-input-choice">
      <div className="r-guests-selector">
        {intl.formatMessage(transDefs.adults)}
        <div className="r-count-stepper">
          <button type="button"
                  disabled={isStepBtnDisabled(-1)}
                  onClick={e => onClick(e, "adults", -1)}>
            <IconMinus />
          </button>
          <span className="val">{value?.adults || 0}</span>
          <button type="button"
                  disabled={isStepBtnDisabled(1)}
                  onClick={e => onClick(e, "adults", 1)}>
            <IconPlus />
          </button>
        </div>
      </div>
      <div className="r-guests-selector">
        {intl.formatMessage(transDefs.children)}
        <div className="r-count-stepper">
          <button type="button"
                  disabled={isStepBtnDisabled(-1, false)}
                  onClick={e => onClick(e, "children", -1)}>
            <IconMinus />
          </button>
          <span className="val">{value?.children || 0}</span>
          <button type="button"
                  disabled={isStepBtnDisabled(1, false)}
                  onClick={e => onClick(e, "children", 1)}>
            <IconPlus />
          </button>
        </div>
      </div>
    </div>);
}

const InputMobileView = (
  {
    name,
    open,
    toggle,
    max,
    value,
    onValueChange
  }: InputViewProps) => {

  const intl = useIntl();

  // dom interactions

  const onSelection = (val: SplittedValue | undefined, e?: MouseEvent) => {
    onValueChange(val);
    e?.stopPropagation();
  }

  const onReset = (e: MouseEvent) => {
    onValueChange(undefined);
    e.stopPropagation();
  }

  // rendering

  const wrapperClasses = classNames("input-wrap", name, { selection: open });

  const editionRendering = useMemo(() => (
    <>
      <h2 onClick={() => toggle(name, false)}>{intl.formatMessage(transDefs.label)}</h2>
      <InputSelection {...{ max, value, onSelection }} />
    </>), [ max, value ]);

  const resumeRendering = useMemo(() => {
    const isSet = !!value && (value.adults + value.children) > 0;
    return(
      <>
        <div className="input-label ellipsed">{intl.formatMessage(transDefs.label)}</div>
        <div className={classNames("input-val-state", { empty: !isSet })}>
          <span className="label ellipsed">{intl.formatMessage(transDefs.placeholder)}</span>
          {isSet && <span className="value ellipsed">{guestsFormattedState(intl, (value.adults + value.children), max)}</span>}
        </div>
        {isSet && <button type="button" className="resetter" onClick={onReset}><IconX /></button>}
      </>);
  }, [ max, value ]);
  return (
    <div tabIndex={0} className={wrapperClasses} onClick={() => !open && toggle(name, true)}>
      {open ? editionRendering : resumeRendering}
    </div>);
}

const InputDesktopView = (
  {
    name,
    open,
    toggle,
    max,
    value,
    onValueChange
  }: InputViewProps) => {

  const intl = useIntl();

  // picker floating display

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: isOpen => toggle(name, isOpen),
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
    middleware: [ offset(8) ]
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { enabled: open, ancestorScroll: true })
  ]);

  // dom interaction

  const onSelection = (val: SplittedValue | undefined, _?: MouseEvent) => onValueChange(val);

  // rendering

  const inputRef = useMergeRefs([ reference ]);
  const inputWrapperClasses = classNames("input-wrap", name);
  const pickerClasses = classNames("rf-searcher-input-dropdown", name);
  const isSet = !!value && (value.adults + value.children) > 0;

  return (
    <>
      <div className={inputWrapperClasses}>
        <div ref={inputRef}
             {...getReferenceProps()}
             tabIndex={0}
             className="input-content inner-space">
          <IconUser />
          <div className="input-val-wrap">
            <div className="input-label ellipsed">{intl.formatMessage(transDefs.label)}</div>
            <div className={classNames("input-val-state", { empty: !isSet })}>
              <span className="label ellipsed">{intl.formatMessage(transDefs.placeholder)}</span>
              {isSet && <span className="value ellipsed">
                {guestsFormattedState(intl, (value.adults + value.children), max)}
              </span>}
            </div>
          </div>
        </div>
        {isSet &&
          <button type="button" className="resetter" onClick={() => onValueChange(undefined)}><IconX /></button>
        }
      </div>
      <FloatingPortal>
        {open &&
          <FloatingFocusManager context={context}>
            <div ref={floating}
              {...getFloatingProps()}
                 className={pickerClasses}
                 style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}>
              <InputSelection {...{ max, value, onSelection }} />
            </div>
          </FloatingFocusManager>
        }
      </FloatingPortal>
    </>);

}

const MainSearchGuestsInput = (
  {
    name,
    open,
    toggle,
    mobileView,
    max = maxDefault,
    value: initedValue,
    onValueChange
  }: MainSearchGuestsInputProps) => {

  const isMounted = useIsMounted();

  // component states

  const [ adults, setAdults ] = useState<number>(Math.min(initedValue || 0, max));
  const [ children, setChildren ] = useState<number>(0);
  const [ value, setValue ] = useState<number>(Math.min(initedValue || 0, max));

  useEffect(() => setValue(adults + children), [ adults, children ]);

  useEffect(() => {
    if (!isMounted) return;
    onValueChange(value);
  }, [ value ]);

  // dom interactions

  const onChange = (value: SplittedValue | undefined) => {
    (adults !== value?.adults) && setAdults(value?.adults || 0);
    (children !== value?.children) && setChildren(value?.children || 0);
  }

  // rendering

  return mobileView
    ? <InputMobileView name={name}
                       open={open}
                       toggle={toggle}
                       max={max}
                       value={{ adults, children}}
                       onValueChange={onChange} />
    : <InputDesktopView name={name}
                        open={open}
                        toggle={toggle}
                        max={max}
                        value={{ adults, children }}
                        onValueChange={onChange} />
    ;

}

export default MainSearchGuestsInput;
