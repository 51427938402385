import React from "react";
import { gql, useQuery } from "@apollo/client";
import { defineMessages, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useAppConfig } from "@ct-react/core";
import { useLocaleNavigate } from "@ct-react/locale";
import { ARTICLE_SUMMARY_FRAGMENT } from "@shared/gql/fragments";
import { rebuildQuerySearchParamsFromMain } from "../tools/search-params/search";
import { useDisplayMobileOnly } from "../tools/breakpoints";
import { MainSearchCriteria } from "../models/search";
import { useSeason } from "../hooks/generic";
import Wrapper from "../components/layout/wrapper";
import Summary from "../components/cards/summary";
import MainSearchForm from "../components/search/main-search-form";
import Comment from "../components/cards/comment";
import SeoHelmet from "../components/seo-helmet";
import summerBg from "../assets/backgrounds/home-head-bg-summer.webp";
import winterBg from "../assets/backgrounds/home-head-bg-winter.webp";
import "./common.scss";
import "./home.scss";

const transDefs = defineMessages({
  catch: { id: "home-catch-phrase", defaultMessage: "Le secret des vacances parfaites dans les Alpes !" },
  searchTitle: { id: "home-search-title", defaultMessage: "Trouvez votre logement alpin idéal." },
  title: { id: "seo-title-home", defaultMessage: "Location de chalets et d'appartements de vacances à la montagne."},
  promo: { id: "home-promo-title", defaultMessage: "Choisissez une offre promotionnelle" },
  summaries: { id: "home-list-title", defaultMessage: "Une sélection qui pourrait vous plaire" },
  reviews: { id: "home-reviews-title", defaultMessage: "Ce que disent nos clients" }
});

const PROMOS_GQL_DATA = gql`
  ${ARTICLE_SUMMARY_FRAGMENT}
  query GetHomePromotions($agencyIds: [ID!]) {
    promos: search(first: 10, where: { any: { promoted: true, merchantIds: $agencyIds } }) {
      edges { node { ... on RentalAccommodationArticle { ...ArticleSummaryFields } } }
    }
  }
`;

const SUMMARIES_GQL_DATA = gql`
  ${ARTICLE_SUMMARY_FRAGMENT}
  query GetHomeSummaries($agencyIds: [ID!]) {
    summaries: articleRentalpRandomize(merchantIds: $agencyIds) {
      ... on RentalAccommodationArticle { ...ArticleSummaryFields }
    }
  }
`;

const REVIEWS_GQL_DATA = gql`
  query GetHomeReviews {
    reviews: reviewsRentalpRandomize { customerName averageRate reviewedAt comment }
  }
`;

const HomePage = () => {

  const intl = useIntl();
  const navigate = useLocaleNavigate();
  const { options: { asAgency } } = useAppConfig();
  const season = useSeason();

  // data loading

  const { loading: summariesLoading, data: summariesData } = useQuery(SUMMARIES_GQL_DATA, {
    variables: { ...(!!asAgency) && { agencyIds: [ asAgency.id ] } },
    ssr: false
  });

  const { loading: reviewsLoading, data: reviewsData } = !asAgency
    ? useQuery(REVIEWS_GQL_DATA, { ssr: false })
    : { loading: false, data: { reviews: [] } };

  const { loading: promoLoading, data: promoData } = useQuery(PROMOS_GQL_DATA, {
    variables: { ...(!!asAgency) && { agencyIds: [ asAgency.id ] } },
    ssr: false
  });

  // dom interaction

  const onSearch = (c: MainSearchCriteria) => {
    const params = new URLSearchParams();
    const { region, ...criteria } = c;
    !!region && params.set("refId", region.id.toString());
    rebuildQuerySearchParamsFromMain(params, criteria);
    navigate(`/resorts/${region?.slug ?? "any"}/search${[...params].length > 0 ? `?${params.toString()}` : ""}`);
  }


  // rendering

  const promos = promoData?.promos.edges.map((e: any) => e.node) || [];
  const summaries = summariesData?.summaries || [];
  const reviews = reviewsData?.reviews || [];
  const isMobile = useDisplayMobileOnly();

  return (
    <main className="rl-home">
      <SeoHelmet title={intl.formatMessage(transDefs.title)} />

      <Wrapper.Backgrounded as="section"
                            className="rlh-head"
                            type="boxed"
                            ratio={isMobile ? 125 : 55}
                            imagePath={season === "winter" ? winterBg : summerBg}>
        <h1 className="catch-phrase">{intl.formatMessage(transDefs.catch)}</h1>
        <h3 className="sub-phrase">{intl.formatMessage(transDefs.searchTitle)}</h3>
        <div className="search-form">
          <MainSearchForm agencyId={asAgency?.id}
                          onCriteriaChange={onSearch}/>
        </div>
      </Wrapper.Backgrounded>

      {(promoLoading || promos.length > 0) &&
        <Wrapper.Slideable as="section"
                           wrapperClassName="rg-carousel-list"
                           withNav={true}
                           title={intl.formatMessage(transDefs.promo)}>
          {promoLoading
            ? <Skeleton containerClassName="rg-skeleton-list" className="rg-card-skeleton" count={10} inline={true} />
            : promos.map((promo: any, i: number) => <Summary key={i} fixedDiscountView={true} {...promo} />)
          }
        </Wrapper.Slideable>
      }

      <Wrapper.Slideable as="section"
                         wrapperClassName="rg-carousel-list"
                         withNav={true}
                         title={intl.formatMessage(transDefs.summaries)}>
        {summariesLoading
          ? <Skeleton containerClassName="rg-skeleton-list" className="rg-card-skeleton" count={10} inline={true} />
          : summaries.map((summary: any, i: number) => <Summary key={i} {...summary} />)
        }
      </Wrapper.Slideable>

      {(reviewsLoading || reviews.length > 0) &&
        <Wrapper.Slideable as="section"
                           data-nosnippet={true}
                           className="rlh-comments"
                           wrapperClassName="rg-carousel-list"
                           withNav={true}
                           title={intl.formatMessage(transDefs.reviews)}>
          {reviewsLoading
            ? <Skeleton containerClassName="rg-skeleton-list" className="rg-review-skeleton" count={10} inline={true} />
            : reviews.map((review: any, i: number) => <Comment key={i} data={review} />)
          }
        </Wrapper.Slideable>
      }

    </main>);

};

export default HomePage;
