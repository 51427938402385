import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { castPrice, Price } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { calculateCartPaymentAmount, CartPaymentAmount, CartPaymentMode } from "@ct-react/calendar";
import { CartItem } from "../../models/cart";
import "./payment-type-choice.scss";

const transDefs = defineMessages({
  fullTitle: { id: "cart-process-payment-choice-full-label", defaultMessage: "Payer le montant total" },
  fullDetail: {
    id: "cart-process-payment-choice-full-detail",
    defaultMessage: "Payez le total maintenant et vous aurez terminé."
  },
  partialTitle: {
    id: "cart-process-payment-choice-partial-label",
    defaultMessage: "Payer une partie maintenant et l'autre plus tard"
  },
  partialDetail: {
    id: "cart-process-payment-choice-partial-detail",
    defaultMessage: "Payez {down} maintenant et le solde ({diff}) vous sera prélevé ultérieurement."
  },
  partialCost: { id: "cart-process-payment-choice-partial-detail-cost", defaultMessage: "Aucun frais supplémentaires." },
  noPartialDetail: {
    id: "cart-process-payment-choice-partial-disabled",
    defaultMessage: "Cette option n'est pas disponible pour cette réservation."
  }
});

type PaymentTypeChoiceFormProps = {
  items: CartItem[];
  onChoice: (choice: CartPaymentMode, detail: CartPaymentAmount) => void;
};

const PaymentTypeChoiceForm = forwardRef<HTMLFormElement, PaymentTypeChoiceFormProps>((
  {
    items,
    onChoice
  }: PaymentTypeChoiceFormProps, forwardedRef) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const [ full, setFull ] = useState<CartPaymentAmount>(calculateCartPaymentAmount(items, "full"));
  const [ partial, setPartial ] = useState<CartPaymentAmount>(calculateCartPaymentAmount(items, "partial"));
  const [ inputValue, setInputValue ] = useState<CartPaymentMode>("full");

  useEffect(() => {
    const full = calculateCartPaymentAmount(items, "full");
    const partial = calculateCartPaymentAmount(items, "partial");
    setFull(full);
    setPartial(partial);
  }, [ items ]);

  useEffect(() => onChoice(inputValue, inputValue === "full" ? full : partial), [ inputValue ]);

  const partialDiff = useMemo<Price>(() => {
    const castedFull = castPrice(full.total);
    const castedPartial = castPrice(partial.total);
    return {
      amount: castedFull.amount - castedPartial.amount,
      currency: castedPartial.currency
    };
  }, [ full, partial ]);

  const activePartial = useMemo<boolean>(() => partialDiff.amount > 0, [ partialDiff ]);

  return (
    <form ref={forwardedRef} className="payment-choice">

      <label className="grouped-radio">
        <input type="radio"
               name="choice"
               id="full"
               value="full"
               checked={inputValue === "full"}
               onChange={() => setInputValue("full")} />
        <span className="full-radio">
          <span className="label">{intl.formatMessage(transDefs.fullTitle)}</span>
          <label htmlFor="full">{print.price(full.total)}</label>
          <span className="checkmark" />
          <span className="detail">{intl.formatMessage(transDefs.fullDetail)}</span>
        </span>
      </label>

      <label className="grouped-radio">
        <input type="radio"
               name="choice"
               id="partial"
               value="partial"
               checked={inputValue === "partial"}
               onChange={() => setInputValue("partial")}
               disabled={!activePartial} />
        <span className="full-radio">
          <span className="label">{intl.formatMessage(transDefs.partialTitle)}</span>
          {activePartial &&
            <label htmlFor="partial">{print.price(partial.total)}</label>
          }
          <span className="checkmark"/>
          {activePartial &&
            <span className="detail">
              <span className="block">
                {intl.formatMessage(transDefs.partialDetail, {
                  down: print.price(partial.total),
                  diff: print.price(partialDiff)
                })}
              </span>
              <span className="block">{intl.formatMessage(transDefs.partialCost)}</span>
            </span>
          }
          {!activePartial &&
            <span className="detail">
              <span className="block">{intl.formatMessage(transDefs.noPartialDetail)}</span>
            </span>
          }
        </span>
      </label>

    </form>);

});

export default PaymentTypeChoiceForm;
