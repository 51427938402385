import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Wrapper from "../components/layout/wrapper";
import SeoHelmet from "../components/seo-helmet";
import "./common-cms.scss";

const transDefs = defineMessages({
  title: {
    id: "seo-title-about",
    defaultMessage: "Qualité garantie grâce à la coopérative C.I.T.I."
  },
  lead: {
    id: "seo-lead-about",
    defaultMessage: "La coopérative C.I.T.I regroupe plus de 50 membres à travers la Suisse. Ces derniers garantissent la qualité des logements en location ainsi que les services associés."
  },
  desc: {
    id: "about-desc",
    defaultMessage: "Créée en juin 2003, à l'initiative de professionnels de la branche, la C.I.T.I. est la Coopérative de l'innovation du Tourisme et de l'Immobilier. Ses membres sont des entreprises actives dans le milieu. La Coopérative répond à des besoins concrets du secteur du tourisme. Elle a pour but de mettre à disposition de ses membres des services informatiques spécifiques. Ces services comprennent la gestion des logements de vacances et la mise en réseau des agences au travers de rentalp.com, notre système de réservation directe."
  },
  descTwo: {
    id: "about-desc-two",
    defaultMessage: "Chaque année, plus de 100'000 clients font confiance aux membres de C.I.T.I pour leurs vacances. rentalp.com propose plus de 5'000 logements de vacances dans les Alpes. La plupart de logements sont classifiés selon la norme de Suisse Tourisme qui vous garantit une qualité irréprochable."
  },
  descThree: {
    id: "about-desc-three",
    defaultMessage: "Chacun de nos membres est bien sûr présent dans la station dans laquelle vous aurez choisi votre logement de vacances et se fera un plaisir de vous accueillir et vous choyer durant votre séjour. Il sera votre interlocuteur afin de satisfaire à toutes vos demandes. Chaque membre C.I.T.I vous garantit de belles vacances sans soucis."
  },
  descFour: {
    id: "about-desc-four",
    defaultMessage: "Merci de choisir C.I.T.I à travers notre plateforme rentalp.com."
  }
});

const AboutPage = () => {

  const intl = useIntl();

  return (
    <Wrapper.Centered as="main" className="rl-about cms-page">

      <SeoHelmet title={intl.formatMessage(transDefs.title)}
                 description={intl.formatMessage(transDefs.lead)}
                 canonical="/about" />

      <Wrapper.InnerTitle as="div" className="descripted-title">
        <h1>{intl.formatMessage(transDefs.title)}</h1>
        <div className="rl-lead">
          <p className="bolder">{intl.formatMessage(transDefs.lead)}</p>
          <p>{intl.formatMessage(transDefs.desc)}</p>
          <p>{intl.formatMessage(transDefs.descTwo)}</p>
          <p>{intl.formatMessage(transDefs.descThree)}</p>
          <p>{intl.formatMessage(transDefs.descFour)}</p>
        </div>
      </Wrapper.InnerTitle>

    </Wrapper.Centered>);

};

export default AboutPage;
