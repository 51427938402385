import { Moment } from "moment";
import { isotizeDate, momentizeDate, Period } from "@ct-react/core";

export const parseRefIdQueryParam = (params: URLSearchParams): string | undefined => params.get("refId") ?? undefined;

export const parseOpenArticleQueryParams = (params: URLSearchParams): Period<Moment> | undefined => {
  const start = params.get("checkin");
  const end = params.get("checkout");
  return (!!start && !!end) ? { start: momentizeDate(start), end: momentizeDate(end) } : undefined;
}

export const rebuildOpenArticleQueryParams = (currentParams: URLSearchParams, query?: Period<Moment>, ) => {
  Array.from(currentParams.keys())
    .filter(pk => pk.match(/^check(in|out)$/))
    .forEach(pk => currentParams.delete(pk));
  if (!!query?.start) currentParams.set("checkin", isotizeDate(query.start));
  if (!!query?.end) currentParams.set("checkout", isotizeDate(query.end));
}
