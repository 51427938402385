import React from "react";
import { gql, useQuery } from "@apollo/client";
import { defineMessages, useIntl } from "react-intl";
import { ADDRESS_FRAGMENT, MERCHANT_CONTACT_FRAGMENT } from "@shared/gql/fragments";
import Wrapper from "../../components/layout/wrapper";
import SeoHelmet from "../../components/seo-helmet";
import AgencyCard from "../../components/cards/agency";
import "../common-cms.scss";

const transDefs = defineMessages({
  title: {
    id: "seo-title-agencies",
    defaultMessage: "Des professionnels de l'immobilier pour vous rendre la vie plus belle."
  },
  desc: {
    id: "seo-desc-agencies",
    defaultMessage: "Fortes de leur expérience, plus de 50 agences de location situées dans les Alpes vous proposent des logements de vacances et des services de qualité."
  },
  descTwo: {
    id: "seo-desc-agencies-two",
    defaultMessage: "Faites leur confiance, réservez dès maintenant!"
  }
});

const GET_AGENCIES = gql`
  ${ADDRESS_FRAGMENT}
  ${MERCHANT_CONTACT_FRAGMENT("RentalpAgency")}
  query GetAgencies {
    agencies: cmsRentalpAgencies { ...RentalpAgencyFields }
  }
`;

const AgencyListPage = () => {

  const intl = useIntl();

  const { loading, data } = useQuery(GET_AGENCIES);
  const agencies = data?.agencies || undefined;

  return (
    <Wrapper.Centered as="main" className="rl-agencies cms-grid">

      <SeoHelmet title={intl.formatMessage(transDefs.title)}
                 description={intl.formatMessage(transDefs.desc)}
                 canonical="/agencies" />

      <Wrapper.InnerTitle as="div" className="descripted-title">
        <h1>{intl.formatMessage(transDefs.title)}</h1>
        <div className="rl-lead">
          <p>{intl.formatMessage(transDefs.desc)}</p>
          <p>{intl.formatMessage(transDefs.descTwo)}</p>
        </div>
      </Wrapper.InnerTitle>

      <section className="cards-wrapper">
        {(loading || !agencies)
          ? [ ...Array(10) ].map((_, i) => <AgencyCard key={i} loading={true} />)
          : agencies.map((a, i) => <AgencyCard key={i} loading={false} data={a} />)
        }
      </section>

    </Wrapper.Centered>);

}

export default AgencyListPage;
