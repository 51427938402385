import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { IconBug, IconChevronsRight } from "@tabler/icons-react";
import { LocaleLink, useLocaleUrl } from "@ct-react/locale";
import Wrapper from "./wrapper";
import "./error-boundary.scss";

const transDefs = defineMessages({
  title: { id: "running-error-title", defaultMessage: "Oups... On dirait que quelque chose c'est mal passé." },
  link: { id: "running-error-link",  defaultMessage: "Veuillez actualiser la page et réessayer ou retourner à la page d'accueil" }
});

export const RunningErrorUi = () => {

  const intl = useIntl();
  const insights = useAppInsightsContext();
  const { currentUrl } = useLocaleUrl();

  useEffect(() => {
    insights.trackEvent({ name: "react-running-error" }, { url: currentUrl });
  }, []);

  return (
    <div className="rl-running-error">
      <Wrapper.Centered as="main">
        <IconBug/>
        <div className="error-refresh">
          <h1>{intl.formatMessage(transDefs.title)}</h1>
          <div className="link">
            {intl.formatMessage(transDefs.link)}
            <LocaleLink to="/" reloadDocument={true}>
              <IconChevronsRight />
            </LocaleLink>
          </div>
        </div>
      </Wrapper.Centered>
    </div>)

}
