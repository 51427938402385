import {useMediaQuery} from "react-responsive";
import {Details} from "express-useragent";

const bp = {
  smallMobile: 375,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  bigDesktop: 1200,
  bigScreen: 1400
};

const defaultWidth = (ua: Pick<Details, "isMobile" | "isTablet">) => {
  if (ua.isMobile && !ua.isTablet) return bp.mobile;
  return bp.tablet;
}

export const useDisplayMobileOnly = () =>
  useMediaQuery({ maxWidth: bp.mobile });

export const useDisplayLandscapeMobileOnly = () =>
  useMediaQuery({ orientation: "landscape", maxHeight: bp.mobile });

export const useDisplayUntilTablet = () =>
  useMediaQuery({ maxWidth: bp.tablet - .02 });

export const useDisplayUntilDesktop = () =>
  useMediaQuery({ maxWidth: bp.desktop - .02 });

export const useDisplayTablet = () => useMediaQuery({ minWidth: bp.tablet });
export const useDisplayDesktop = () => useMediaQuery({ minWidth: bp.desktop });

export default defaultWidth;
