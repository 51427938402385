import React, { useCallback, useMemo } from "react";
import { classNames, Price as PriceModel } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { BookFullPrice, isDiscountedPrice, BookingDetailedPrice } from "@ct-react/calendar";
import { DataProps } from "../../tools/components";
import "./price.scss"

type PriceProps = DataProps<BookFullPrice> & { total?: boolean };

const Price = (
  {
    className,
    total = false,
    data
  }: PriceProps) => {

  const { print } = useLocaleFormatter();

  const discounted = useMemo(() => isDiscountedPrice(data), [ data ]);
  const calculateDiscount = useCallback((detail: BookingDetailedPrice<PriceModel>): PriceModel => {
    const discountAmount = detail.discount?.amount || 0;
    return { amount: detail.bare.amount + discountAmount, currency: detail.bare.currency };
  }, []);

  const wrapperClasses = classNames("r-price", className, { discounted: !total && discounted });

  return (
    <span className={wrapperClasses}>
      {(!total && discounted) &&
        <span className="origin">{print.price(data.detailed.bare)}</span>
      }
      {print.price(total ? data.amount : calculateDiscount(data.detailed))}
    </span>);

}

export default Price;
