import React from "react";
import {useIntl} from "react-intl";
import {IconAddressBook, IconShoppingCart, IconWallet} from "@tabler/icons-react";
import {classNames} from "@ct-react/core";
import {cartStepTransDefs} from "../../pages/cart/cart-outlet";
import "./checkout-stepper.scss";

const sortedIcons = [ <IconShoppingCart />, <IconAddressBook />, <IconWallet /> ];

type CheckoutStepperProps = {
  className?: string;
  activeIndex?: number;
}

const CheckoutStepper = (
  {
    className,
    activeIndex = 0
  }: CheckoutStepperProps) => {

  const intl = useIntl();
  const wrapperClasses = classNames("r-stepper-wrapper", className);

  return (<div className={wrapperClasses}>
    {Object.entries(cartStepTransDefs).map(([ key, translation], i) => (
      <React.Fragment key={key}>
        {i > 0 && <div className={classNames("connector", { selected: i + 1 <= activeIndex })} />}
        <div className={classNames("one-step", { selected: i < activeIndex })}>
          <span className="badge">{sortedIcons[i]}</span>
          <span className="label">{intl.formatMessage(translation)}</span>
        </div>
      </React.Fragment>
    ))}
  </div>);

}

export default CheckoutStepper;
