import React from "react";
import { RouteObject, useRoutes } from "react-router";
import { allLocales } from "@shared/locales";
import { NotFoundWithoutLocalePrefix, ProtectedRoute } from "./code-splitting/client-only-ui";
import HomePage from "./pages/home";
import Search from "./pages/search";
import OpenArticle from "./pages/open-article";
import AboutPage from "./pages/about";
import NotFoundPage from "./pages/not-found";
import CartOutlet from "./pages/cart/cart-outlet";
import Cart from "./pages/cart/cart";
import CartInformation from "./pages/cart/information";
import CartAccount from "./pages/cart/account";
import CartPayment from "./pages/cart/payment";
import CartPaymentCallback from "./pages/cart/payment-callback";
import AgencyListPage from "./pages/agencies/list";
import AgencyPage from "./pages/agencies/open-agency";
import ResortsPage from "./pages/resorts/list";
import ResortPage from "./pages/resorts/open-resort";
import ProfilePage from "./pages/me/profile";

const routes: RouteObject[] = [
  {
    path: "",
    element: <HomePage />,
    index: true,
  },
  {
    path: "agencies",
    children: [
      {
        path: "",
        index: true,
        element: <AgencyListPage />
      },
      {
        path: ":slug",
        children: [
          {
            // must have a refId query param has agency identifier ex: /agencies/citi-demo?refId=guid
            path: "",
            element: <AgencyPage />,
            index: true
          },
          // must have a refId query param has agency identifier ex: /agencies/citi-demo/search?refId=guid
          {
            path: "search",
            element: <Search refContext="agency" />
          },
          {
            // must have a refId query param has article identifier ex: /agencies/citi-demo/article-name?refId=guid
            path: ":slug",
            element: <OpenArticle />
          }
        ]
      }
    ]
  },
  {
    path: "resorts",
    children: [
      {
        path: "",
        index: true,
        element: <ResortsPage />
      },
      {
        path: ":slug",
        children: [
          // must have a refId query params has resort identifier ex: /resorts/nax?refId=int
          {
            path: "",
            element: <ResortPage />
          },
          {
            // must have a refId query params has resort identifier ex: /resorts/nax/search?refId=int
            path: "search",
            element: <Search refContext="resort" />
          }
        ]
      }
    ]
  },
  {
    path: "cart",
    element: <CartOutlet />,
    children: [
      {
        path: "",
        index: true,
        element: <Cart />
      },
      {
        path: "account",
        element: <CartAccount />
      },
      {
        path: "information",
        element: <CartInformation />
      },
      {
        path: "payment",
        children: [
          {
            path: "",
            index: true,
            element: <CartPayment />
          },
          {
            path: "success",
            element: <CartPaymentCallback succeeded={true} />
          },
          {
            path: "cancellation",
            element: <CartPaymentCallback succeeded={false} />
          }
        ]
      }
    ]
  },
  {
    path: "about",
    element: <AboutPage />
  },
  {
    path: "me",
    children: [
      {
        path: "profile",
        element: <ProtectedRoute><ProfilePage/></ProtectedRoute>
      }
    ]
  },
  {
    path: "*",
    element: <NotFoundPage />
  }
];

const LocaleRoutes = () => useRoutes([
  ...allLocales.map(l => ({
    path: l.basename,
    children: routes
  } as RouteObject)),
  {
    path: "*",
    element: <NotFoundWithoutLocalePrefix />
  }
]);

export default LocaleRoutes;
