import React, { FormEvent, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { gql, useQuery } from "@apollo/client";
import { IconSearch } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { globalTranslations } from "../../i18n/sharable-defs";
import { useDisplayMobileOnly } from "../../tools/breakpoints";
import { defineMonths } from "../../tools/moments";
import { BookingConfig, MainSearchCriteria, Resort } from "../../models/search";
import { Button } from "../common/minimals";
import Modal, { InnerModal } from "../common/modal";
import MainSearchRegionInput, { regionFormattedState } from "./search-inputs/region-input";
import MainSearchPeriodInput, { periodFormattedState } from "./search-inputs/period-input";
import MainSearchGuestsInput, { guestsFormattedState } from "./search-inputs/guests-input";
import "./main-search-form.scss";
import { useLocaleFormatter } from "@ct-react/locale";

const RESORTS_GQL_DATA = gql`
  query ResortsList($agencyId: ID) {
    resorts: searchableAccommodationRegions(merchantId: $agencyId) { id slug name }
  }
`;

type MainSearchFormProps = {
  className?: string;
  agencyId?: string;
  criteria?: MainSearchCriteria;
  onCriteriaChange?: (criteria: MainSearchCriteria) => void;
}

const MainSearchForm = (
  {
    className,
    agencyId,
    criteria = {},
    onCriteriaChange = () => void 0
  }: MainSearchFormProps) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();
  const isMobile = useDisplayMobileOnly();

  // component states

  const [ region, setRegion ] = useState<Resort | undefined>(criteria.region);
  const [ period, setPeriod ] = useState<BookingConfig | undefined>(criteria.period);
  const [ guests, setGuests ] = useState<number | undefined>(criteria.guests);

  const [ isOpened, setIsOpened ] = useState<boolean>(false);
  const [ openedInput, setOpenedInput ] = useState<string | undefined>(undefined);

  // data loading

  const { data } = useQuery<{ resorts: Resort[] }>(RESORTS_GQL_DATA, { ssr: false, variables: { agencyId } });
  const monthsList = useRef(defineMonths(moment(), 12));

  // dom interactions

  const onInputToggle = (name: string, isOpen: boolean) => {
    setIsOpened(isOpen);
    if (isOpen && openedInput !== name) {
      setOpenedInput(name);
      return;
    }
    setOpenedInput(undefined);
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsOpened(false);
    onCriteriaChange({ region, period, guests });
  }

  // rendering

  const wrapperClasses = classNames("rf-searcher", className, { mobile: isMobile });

  const inputs = (
    <>
      <MainSearchRegionInput name="regions"
                             open={openedInput === "regions"}
                             toggle={onInputToggle}
                             mobileView={isMobile}
                             resortsList={data?.resorts || []}
                             value={region}
                             onValueChange={setRegion} />
      <MainSearchPeriodInput name="period"
                             open={openedInput === "period"}
                             toggle={onInputToggle}
                             mobileView={isMobile}
                             monthsList={monthsList.current}
                             value={period}
                             onValueChange={setPeriod} />
      <MainSearchGuestsInput name="guests"
                             open={openedInput === "guests"}
                             toggle={onInputToggle}
                             mobileView={isMobile}
                             value={guests}
                             onValueChange={setGuests} />
    </>);

  if (isMobile) {

    const submitter = (
      <Button type="submit" className="inline-icon">
        <IconSearch/>
        {intl.formatMessage(globalTranslations.search)}
      </Button>);

    return (
      <div className={wrapperClasses}>
        <Modal open={isOpened}
               onOpenChange={setIsOpened}>

          <InnerModal.Trigger as="div" className="input-wrap inner-space m-resume">
            <div className="r-btn link rounded-icon"><IconSearch/></div>
            <div className="resort ellipsed">{regionFormattedState(intl, region, data?.resorts || [])}</div>
            <div className="others ellipsed">
              {`${periodFormattedState(intl, print.period, period)} • ${guestsFormattedState(intl, guests)}`}
            </div>
          </InnerModal.Trigger>

          <InnerModal.Content footer={submitter}
                              as="form"
                              className="rf-searcher-mobile-modal-wrap"
                              onSubmit={e => onSubmit(e)}>
            {inputs}
          </InnerModal.Content>

        </Modal>
      </div>);

  }

  return (
    <form className={wrapperClasses}
          onSubmit={onSubmit}>
      {inputs}
      <div className="submitter inner-space">
        <Button type="submit" className="rounded-icon">
          <IconSearch/>
        </Button>
      </div>
    </form>);

};

export default MainSearchForm;
