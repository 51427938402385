import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {IconChevronsRight, IconInfoSquareRoundedFilled, IconLanguageOff} from "@tabler/icons-react";
import {classNames} from "@ct-react/core";
import "./info-box.scss";
import {LocaleLink} from "@ct-react/locale";

type SimpleInfoBoxProps = { msg?: string };
type InfoBoxProps = Omit<SimpleInfoBoxProps, "msg"> & {
  className?: string;
  tablerIcon?: JSX.Element,
  msg: string,
  href?: string;
  asLink?: boolean;
  reverse?: boolean;
}

const InfoBox = (
  {
    className,
    tablerIcon = <IconInfoSquareRoundedFilled />,
    msg,
    href,
    asLink = false,
    reverse = false
  }: InfoBoxProps) => {

  const wrapperClasses = classNames("r-info-box", className, { asLink, reverse });
  const innerRendering = useMemo(() => (
    <>
      <span className="icon">{tablerIcon}</span>
      <span className="txt">{msg}</span>
      {(!!href || asLink) && <IconChevronsRight />}
    </>
  ), [ tablerIcon, msg ]);

  return !!href
    ? (<LocaleLink to={href} className={wrapperClasses}>{innerRendering}</LocaleLink>)
    : (<span className={wrapperClasses}>{innerRendering}</span>);

}

export default InfoBox;

export const LangWarning = ({ msg }: SimpleInfoBoxProps) => {

  const intl = useIntl();
  const defaultTransDef = intl.formatMessage({
    id: "lang-warning-label",
    defaultMessage: "Informations indisponibles dans votre langue."
  });

  return (<InfoBox msg={msg || defaultTransDef}
                   tablerIcon={<IconLanguageOff />} />);

}
