import { useMemo, useRef } from "react";
import moment, { Moment } from "moment";
import { useIntl } from "react-intl";
import { bookingTranslations } from "../i18n/sharable-defs";

export const useSeason = () => {

  const today = useRef(moment());
  const summerLimit = useRef([
    moment(`${today.current.format("YYYY")}-04-01`),
    moment(`${today.current.format("YYYY")}-09-30`),
  ]);

  const isSummer = today.current.isBetween(summerLimit.current[0], summerLimit.current[1], "day", "[]");
  return isSummer ? "summer" : "winter";

}

export const usePickerLabelize = () => {

  const intl = useIntl();

  return useMemo(() => ({
    month: (m: Moment) => intl.formatDate(m.toDate(), { month: "long", year: "numeric" }),
    dayOfWeek: (d: Moment) => intl.formatDate(d.toDate(), { weekday: "short" }),
    onlineRestricted: intl.formatMessage(bookingTranslations.onDemandOnly)
  }), [ intl ]);

}
