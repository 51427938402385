import React, {FC} from "react";
import {useIntl} from "react-intl";
import {IconBed, IconHome, IconUsers} from "@tabler/icons-react";
import {ResumeFeaturesData} from "../../models/article";
import "./resume-features.scss";

type ResumeFeaturesProps = ResumeFeaturesData & { className?: string };

const ResumeFeatures: FC<ResumeFeaturesProps> = (props) => {

  const intl = useIntl();
  if (Object.keys(props).length === 0)
    return <></>;

  return (
    <ul className={[ ...(!!props.className ? [ props.className ] : []), "r-resume-features" ].join(" ")}>
      {!!props.rooms &&
        <li>
          <IconHome />
          <span>
            {intl.formatMessage({
              id: "card-features-rooms",
              defaultMessage: "{rooms, plural, =1 {# pièce} other {# pièces}}"
            }, props)}
          </span>
        </li>
      }
      {!!props.maxGuests &&
        <li>
          <IconUsers />
          <span>
            {intl.formatMessage({
              id: "card-features-guests",
              defaultMessage: "{maxGuests, plural, =1 {# invité} other {# invités}}"
            }, props)}
          </span>
        </li>
      }
      {!!props.bedrooms &&
        <li>
          <IconBed />
          <span>
            {intl.formatMessage({
              id: "card-features-bedrooms",
              defaultMessage: "{bedrooms, plural, =1 {# chambre} other {# chambres}}"
            }, props)}
          </span>
        </li>
      }
    </ul>
  );
}

export default ResumeFeatures;
