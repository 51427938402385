import React from "react";
import { IconShoppingCart } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { useLocaleNavigate } from "@ct-react/locale";
import { useCartBadge } from "../../../hooks/cart-step-check";
import "./common.scss";

const CartLink = () => {

  const itemsCount = useCartBadge();
  const navigate = useLocaleNavigate();

  return (
    <button className={classNames("main-nav-item", { badged: itemsCount > 0 })}
            onClick={() => navigate("/cart")}>
      <IconShoppingCart />
      {itemsCount > 0 && <span className="badge">{itemsCount}</span>}
    </button>);

}

export default CartLink;
