import React, { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { IconChevronLeft, IconChevronRight, IconDots } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { DataProps } from "../../tools/components";
import "./result-pagination.scss";

type PageInfoProps = {
  currentPage: number;
  pageSize: number;
  pageCount: number;
  previousPageCursor?: string;
  nextPageCursor?: string;
  linkPageCursors?: { page: number, cursor: string }[];
  lastPageCursor?: string;
}

type ResultPaginationProps = DataProps<PageInfoProps> & {
  onPageChange?: () => void;
}

const ResultPagination = (
  {
    className,
    data,
    onPageChange = () => void 0
  }: ResultPaginationProps
) => {

  const location = useLocation();
  const buildPageUrl = useCallback((index: "first" | "last" | "previous" | "next" | number): string => {
    const copy = new URLSearchParams(location.search);
    copy.delete("page_before_cursor");
    copy.delete("page_after_cursor");
    switch (index) {
      case "previous":
        !!data.previousPageCursor && copy.set("page_before_cursor", data.previousPageCursor!);
        break;
      case "next":
        copy.set("page_after_cursor", data.nextPageCursor!);
        break;
      case "last":
        copy.set("page_after_cursor", data.lastPageCursor!);
        break;
    }
    if (typeof index === "number")
      copy.set("page_after_cursor", data.linkPageCursors!.find(l => l.page === index)!.cursor);
    return copy.size > 0 ? `${location.pathname}?${copy.toString()}` : location.pathname;
  }, [ location, data ]);

  if (data.pageCount < 2)
    return null;

  const wrapperClasses = classNames("rls-pagination-wrapper", className);

  return (
    <div className={wrapperClasses}>
      <ul>
        <li>
          <Link to={buildPageUrl(data.currentPage - 1 === 1 ? "first" : "previous")}
             aria-disabled={data.currentPage === 1}
             onClick={() => { if (data.currentPage === 1) return; onPageChange(); }}>
              <IconChevronLeft />
          </Link>
        </li>
        <li className={classNames({ current: data.currentPage === 1 })}>
          <Link to={buildPageUrl("first")}
                aria-disabled={data.currentPage === 1}
                onClick={() => { if (data.currentPage === 1) return; onPageChange(); }}>1</Link>
        </li>
        {!!data.linkPageCursors && data.linkPageCursors[0].page > 2 &&
          <li>
            <button type="button" className="dots"><IconDots /></button>
          </li>
        }
        {!!data.linkPageCursors && data.linkPageCursors.map(({ page: p }, i) => (
          <li key={i}
              className={classNames({ current: data.currentPage === p })}>
            <Link to={buildPageUrl(p)}
                  aria-disabled={data.currentPage === p}
                  onClick={() => { if (data.currentPage === p) return; onPageChange(); }}>{p}</Link>
          </li>
        ))}
        {!!data.linkPageCursors && data.linkPageCursors[data.linkPageCursors.length - 1].page < data.pageCount - 1 &&
          <li>
            <button type="button" className="dots"><IconDots /></button>
          </li>
        }
        <li className={classNames({ current: data.currentPage === data.pageCount })}>
          <Link to={buildPageUrl("last")}
                aria-disabled={data.currentPage === data.pageCount}
                onClick={() => { if (data.currentPage === data.pageCount) return; onPageChange(); }}>{data.pageCount}</Link>
        </li>
        <li>
          <Link to={buildPageUrl(data.currentPage + 1 === data.pageCount ? "last" : "next")}
                aria-disabled={data.currentPage === data.pageCount}
                onClick={() => { if (data.currentPage === data.pageCount) return; onPageChange(); }}>
            <IconChevronRight />
          </Link>
        </li>
      </ul>
    </div>);

}

export default ResultPagination;
