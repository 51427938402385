import React, { useMemo, useState } from "react";
import { gql, makeReference, useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { useLocation, useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { IconChevronsRight } from "@tabler/icons-react";
import { LocaleLink, useLocaleContext, useLocaleNavigate } from "@ct-react/locale";
import { calculateCartPaymentAmount } from "@ct-react/calendar";
import { bookingTranslations } from "../../i18n/sharable-defs";
import { FULL_CART_GQL_DATA } from "@shared/gql/queries";
import { CartItem as CartItemModel, CartItemDownPaymentConfig } from "../../models/cart";
import { CartContext } from "./cart-outlet";
import CartItem from "../../components/cart/cart-item";
import { CartItemCharge, CartPrice } from "../../components/cart/cart-price";
import LastMinuteWarning from "../../components/cart/last-minute-warning";
import "./common.scss";
import "./cart.scss";

const REMOVE_CART_ITEM_GQL_MUT = gql`
  mutation RemoveFromCart($sessionId: String, $userId: String, $itemId: UUID!) {
    cartRemoveItem(sessionId: $sessionId, userId: $userId, item: $itemId) {
      cartId
      allowsMultiItems
      fullPrice { amount discount }
    }
  }
`;

const Cart = () => {

  const intl = useIntl();
  const { locale } = useLocaleContext();
  const navigate = useLocaleNavigate();
  const { state } = useLocation();

  const { sessionId, userId, changeIndex } = useOutletContext<CartContext>();
  const [ removingItem, setRemovingItem ] = useState<string | undefined>(undefined);

  // data loading and mutation

  const { data, loading } = useQuery(FULL_CART_GQL_DATA, {
    variables: { sessionId, userId },
    onCompleted: () => changeIndex(1)
  });

  const [ removeFromCart ] = useMutation(REMOVE_CART_ITEM_GQL_MUT, {
    update: (cache, { data: { cartRemoveItem: payload } }) => cache.modify({
      id: cache.identify(makeReference(`Cart:${payload.cartId}`)),
      fields: {
        // @ts-ignore
        items: (cached: CartItemModel[] = []) => {
          if (!payload.allowsMultiItems) return [];
          return cached.filter(item => item.itemId !== payload.itemId);
        },
        itemsCount: (cached: number = 0) => payload.allowsMultiItems ? cached - 1 : 0,
        fullPrice: () => payload.fullPrice
      }
    }),
    refetchQueries: [ "CartBadge" ]
  });

  // dom interaction

  const onRemoveItem = (itemId: string) => {
    const variables = { sessionId, userId, itemId };
    setRemovingItem(itemId);
    removeFromCart({ variables }).then(() => setRemovingItem(undefined));
  }

  // rendering

  const nextButtonRendering = useMemo(() =>
    <button type="button"
            className="step-btn"
            onClick={() => navigate("/cart/information", { state })}>
      {intl.formatMessage(bookingTranslations.book)}
    </button>, [ locale ]);

  const innerItemNextRendering = useMemo(() => {
    if (!data || !data.cart || data.cart.items.length !== 1) return undefined;
    return (<div className="stepper-actions">{nextButtonRendering}</div>);
  }, [ data ]);

  // calculation and display of full price if cart has more than one item
  const chargeSumRendering = useMemo(() => {
    if (!data || !data.cart || data.cart.items < 2) return null;
    const configs = data.cart.items.map((item: CartItemModel) => item.downPayment);
    const rate = configs.map((config: CartItemDownPaymentConfig) => config.rate).reduce((acc: number, cur: number) => acc + cur, 0) / configs.length;
    const providerCharge = calculateCartPaymentAmount(data.cart.items, "full").detailed.providerCharge;
    return (
      <>
        {!!providerCharge && <CartItemCharge data={{ rate, providerCharge }} />}
        {rate === 1 && <LastMinuteWarning />}
      </>);
  }, [ data ]);

  if (loading)
    return (<Skeleton className="cart-skeleton" />);

  if (!data || !data?.cart || data.cart.items.length === 0)
    return (
      <div className="cart-item-wrapper empty-cart">
        <div className="title">
          {intl.formatMessage({
            id: "cart-empty-title",
            defaultMessage: "Vous n'avez encore rien trouvé ?"
          })}
        </div>
        <div className="redirect">
          {intl.formatMessage({
            id: "cart-empty-redirect",
            defaultMessage: "Parcourez tous les hébergements disponibles en suivant ce lien."
          })}
          <LocaleLink to="/resorts/any/search">
            <IconChevronsRight />
          </LocaleLink>
        </div>
      </div>);

  return (
    <section className="cart-content">
      {data.cart.items.map((item: CartItemModel, i: number) =>
        <CartItem key={i}
                  className="cart-item-wrapper"
                  data={item}
                  nextStep={innerItemNextRendering}
                  actionRemoving={!!removingItem && removingItem === item.itemId}
                  onRemove={onRemoveItem} />
      )}
      {data.cart.items.length > 1 &&
        <div className="cart-full-price rl-two-col stepper-actions">
          <div />
          <div className="cart-item-wrapper margin">
            <CartPrice data={data.cart.fullPrice} />
            {chargeSumRendering}
            {nextButtonRendering}
          </div>
        </div>
      }

    </section>);

}

export default Cart;
