import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useLocaleContext, useLocaleNavigate } from "@ct-react/locale";
import { globalTranslations } from "../../i18n/sharable-defs";
import { useCartBadge } from "../../hooks/cart-step-check";
import { CartContext } from "./cart-outlet";
import { Button, LinkAsButton } from "../../components/common/minimals";
import "./common.scss";
import "./account.scss";

const CartAccount = () => {

  const intl = useIntl();
  const navigate = useLocaleNavigate();
  const { state } = useLocation();
  const { locale } = useLocaleContext();

  const itemsCount = useCartBadge();
  const { userId, changeIndex } = useOutletContext<CartContext>();

  const [ loading, setLoading ] = useState<boolean>(true);
  const accountTarget = useMemo(() => `/${locale.basename}/cart/information`, [ locale ]);

  useEffect(() => {
    itemsCount === 0 && navigate("/cart");
    !!userId && navigate("/cart/information", { state: { ...state, chosenVisitorType: true } });
    setLoading(false);
    changeIndex(2);
  }, []);

  // rendering

  if (loading)
    return (<Skeleton className="cart-skeleton" />);

  return (
    <div className="cart-item-wrapper rl-two-col separator account">
      <div>
        <h2>
          {intl.formatMessage({
            id: "cart-process-account-log-title",
            defaultMessage: "S'identifier"
          })}
        </h2>
        <dl className="account-options">
          <dt>
            {intl.formatMessage({
              id: "cart-process-account-login-option",
              defaultMessage: "Je suis déjà inscrit:"
            })}
          </dt>
          <dd>
            <LinkAsButton className="bolder"
                          href={`/login?target=${accountTarget}`}>
              {intl.formatMessage(globalTranslations.login)}
            </LinkAsButton>
          </dd>
          <dt>
            {intl.formatMessage({
              id: "cart-process-account-signup-option",
              defaultMessage: "C'est la première fois que je réserve sur rentalp:"
            })}
          </dt>
          <dd>
            <LinkAsButton className="bolder"
                          href={`/signup?target=${accountTarget}`}>
              {intl.formatMessage(globalTranslations.signup)}
            </LinkAsButton>
          </dd>
        </dl>
      </div>
      <div>
        <h2>
          {intl.formatMessage({
            id: "cart-process-account-anonym-title",
            defaultMessage: "Continuer sans compte"
          })}
        </h2>
        <dl className="account-options">
          <dt>
            {intl.formatMessage({
              id: "cart-process-account-anonym-option",
              defaultMessage: "Je souhaite faire une réservation à titre de visiteur et renoncer aux avantages réservés aux titulaires d'un compte en ligne:"
            })}
          </dt>
          <dd>
            <Button className="bolder"
                    onClick={() => navigate("/cart/information", { state: { chosenVisitorType: true } })}>
              {intl.formatMessage(globalTranslations.continue)}
            </Button>
          </dd>
        </dl>
      </div>
    </div>);

}

export default CartAccount;
