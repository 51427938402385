import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from "embla-carousel-react";
import { IconChevronLeft, IconChevronRight, IconX } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { DataProps } from "../../tools/components";
import { AnyMedia, isImageMedia } from "../../models/medias";
import { Button } from "./minimals";
import "./lightbox.scss";

export type LightboxHandle = {
  open: (index?: number) => void;
}

const Lightbox = forwardRef<LightboxHandle, DataProps<AnyMedia[]>>(({ data }, forwardedRef) => {

  const [ open, setOpen ] = useState<boolean>(false);
  const requestedIndex = useRef<number>(0);
  const [ previousable, setPreviousable ] = useState<boolean>(false);
  const [ nextable, setNextable ] = useState<boolean>(false);

  const { context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate
  });

  useInteractions([
    useDismiss(context, { referencePress: false, outsidePress: false })
  ]);

  const [ emblaRef, emblaApi ] = useEmblaCarousel({ align: "start" });

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPreviousable(emblaApi.canScrollPrev());
    setNextable(emblaApi.canScrollNext());
  }, []);

  const scroll = useCallback((direction: -1 | 1) => {
    !!emblaApi && (direction < 0 ? emblaApi.scrollPrev() : emblaApi.scrollNext());
  }, [ emblaApi ]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", onSelect);
    emblaApi.scrollTo(requestedIndex.current, true);
    return () => emblaApi.destroy();
  }, [ emblaApi ]);

  useImperativeHandle(forwardedRef, () => ({
    open: (index?: number) => {
      if(!!index) requestedIndex.current = index;
      !open && setOpen(true);
    }
  }));

  return (
    <>
      {open &&
        <FloatingPortal>
          {/*//@ts-ignore*/}
          <FloatingOverlay lockScroll className="rl-full-overlay" />
          <FloatingFocusManager context={context}>
            <div className="rl-full-lightbox">
              <div className="slider-header">
                <Button type="button" className="rounded-icon" onClick={() => setOpen(false)}>
                  <IconX />
                </Button>
              </div>
              <div className="slider-content">
                <Button className={classNames("rounded-icon nav prev", { inactive: !previousable })}
                        onClick={() => scroll(-1)}>
                  <IconChevronLeft />
                </Button>
                <div ref={emblaRef} className="slider-zone">
                  <div className="slider-track">
                    {data.map((item, i) => {
                      const classes = classNames("slider-item")
                      return isImageMedia(item)
                        ? <img key={i} className={classes}
                               src={item.assets[0].url} loading="lazy" alt="" />
                        : <iframe key={i} className={classes}
                                  src={item.url} />;
                    })}
                  </div>
                </div>
                <Button className={classNames("rounded-icon nav next", { inactive: !nextable })}
                        onClick={() => scroll(1)}>
                  <IconChevronRight />
                </Button>
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      }
    </>);

});

export default Lightbox;
