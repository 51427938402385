export type ValueType = string | number | boolean | undefined | null;
export type FormValidatorProcess = (val?: ValueType) => boolean;

export type FormInputConfigDefinition = {
  defaultValue?: ValueType;
  validator?: FormValidatorProcess
}

export type FormInputState = {
  value: any;
  dirty: boolean;
}

export type FormInputDefinition = FormInputConfigDefinition | ValueType;
export type FormDefinition = Record<string, FormInputDefinition>;
export type FormState = Record<string, FormInputState>;

export const notEmptyFormValidator: FormValidatorProcess = (val?: ValueType) => {
  if (typeof val === "number") return true;
  if (!val) return false;
  if (typeof val === "string") return val.trim().length > 0;
  return true;
}

export const minLengthFormValidator = (min: number):FormValidatorProcess => (val?: ValueType) => {
  if (!val) return false;
  if (typeof val === "string") return val.trim().length >= min;
  if (typeof val === "number") return val >= min;
  return true;
}

export const emailFormValidator: FormValidatorProcess = (val: ValueType) => {
  if (typeof val !== "string") return false;
  return notEmptyFormValidator(val) && new RegExp(/^[a-zA-Z0-9][-a-zA-Z0-9._]+@([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,5}$/).test(val);
}

export const phoneFormValidator: FormValidatorProcess = (val: ValueType) => {
  if (typeof val !== "string") return false;
  return notEmptyFormValidator(val) && new RegExp(/^\+(?:[0-9]\s?){6,14}[0-9]$/).test(val);
}
