import React, {createRef, useCallback} from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { IconMenu2, IconUserCircle } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import {LocaleLink, useLocaleContext} from "@ct-react/locale";
import { useVisitor } from "@ct-react/visitor";
import { globalTranslations } from "../../../i18n/sharable-defs";
import Dropdown, {InnerDropdown} from "../../common/dropdown";
import "./common.scss";

const UserMenuDropdown = ({ close = () => void 0 }: { close?: () => void }) => {

  const intl = useIntl();
  const { locale } = useLocaleContext();
  const { isLoggedIn } = useVisitor();
  const location = useLocation();

  const target = useCallback((logout: boolean = false) => {
    return logout && new RegExp("^\/.*\/me\/.*").test(location.pathname)
      ? encodeURIComponent(`/${locale.basename}/`)
      : encodeURIComponent(location.pathname + location.search + location.hash);
  }, [ location ]);

  if (isLoggedIn)
    return (<ul>

      <li>
        <LocaleLink to="/me/profile" onClick={() => close()}>
          {intl.formatMessage(globalTranslations.profile)}
        </LocaleLink>
      </li>

      <li>
        <a href={`/logout?target=${target(true)}`}>
          {intl.formatMessage(globalTranslations.logout)}
        </a>
      </li>
    </ul>);

  return (<ul>
    <li>
      <a href={`/signup?target=${target()}`}>
        {intl.formatMessage(globalTranslations.signup)}
      </a>
    </li>
    <li>
      <a href={`/login?target=${target()}`}>
        {intl.formatMessage(globalTranslations.login)}
      </a>
    </li>
  </ul>);

}

const UserMenu = () => {

  const { isLoggedIn } = useVisitor();
  const dropdownRef = createRef<any>();

  const buttonClasses = classNames("main-nav-item bordered user-menu");
  const userIconWrapperClasses = classNames("wrap-user-ico", { connected: isLoggedIn });

  return (
    <Dropdown ref={dropdownRef} placement="bottom-end">

      <InnerDropdown.Trigger as="button" className={buttonClasses}>
        <IconMenu2 className="ico-smaller"/>
        <div className={userIconWrapperClasses}>
          <IconUserCircle/>
        </div>
      </InnerDropdown.Trigger>

      <InnerDropdown.Content className="main-nav-drop">
        <UserMenuDropdown close={() => dropdownRef.current.close()} />
      </InnerDropdown.Content>

    </Dropdown>);

}

export default UserMenu;
