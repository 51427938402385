import {ArticleDataUrl, DataUrl} from "./models/urls";

export const buildAgencyUrl = (agency: DataUrl, search: boolean = false) =>
  `/agencies/${agency.slug}${search ? "/search" : ""}?refId=${agency.id}`;

export const buildResortUrl = (resort: DataUrl, search: boolean = false) =>
  `/resorts/${resort.slug}${search ? "/search" : ""}?refId=${resort.id}`;

export const buildArticleUrl = (article: ArticleDataUrl) =>
  `/agencies/${article.merchant.slug}/${article.slug}?refId=${article.id}`;
