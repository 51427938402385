export type Asset = {
  breakpoint?: "SM" | "MD" | "LG";
  url: string;
}

export type Image = {
  main: boolean;
  aspectRatio: string;
  assets: Asset[];
};

export enum Ratio {
  FOUR_THREE = "ratio-4-3",
  THREE_TWO = "ratio-3-2",
  SIXTEEN_NINE = "ratio-16-9",
  NINE_SIXTEEN = "ratio-9-16",
  FULL_CARD = "ratio-card",
  SQUARE = "ratio-square"
}

export type ExternalMedia = {
  url: string;
}

export type AnyMedia = Image | ExternalMedia;
export const isImageMedia = (media: AnyMedia): media is Image => "assets" in media;
