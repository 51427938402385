import React from "react";
import {useIntl} from "react-intl";
import {IconAddressBook, IconAt, IconDeviceLandlinePhone, IconPrinter, IconWorldWww} from "@tabler/icons-react";
import {classNames} from "@ct-react/core";
import {agencyTranslations} from "../../i18n/sharable-defs";
import {Agency} from "../../models/agency";
import {DataProps} from "../../tools/components";
import {Button} from "../common/minimals";
import "./agency-contact-box.scss";

type AgencyContactBox = DataProps<Agency> & {
  onContact?: (agency: Agency) => void;
}

const AgencyContactBox = (
  {
    className,
    data,
    onContact
  }: AgencyContactBox) => {

  const intl = useIntl();
  const wrapperClasses = classNames("agency-cbox", className);

  return (
    <div className={wrapperClasses}>

      <div className="cbox-header">
        <span className="picto"><IconAddressBook /></span>
        {intl.formatMessage(agencyTranslations.contactLabel)}
      </div>

      <address>
        <span className="bolder">{data.name}</span>
        {!!data.nameComplement && <span>{data.nameComplement}</span>}
        {!!data.address.streetComplement && <span>{data.address.streetComplement}</span>}
        <span>{data.address.street}</span>
        <span>{`${data.address.zip} ${data.address.city}`}</span>
        {(!!data.phone || !!data.email || !!data.fax || !!data.website) && <div className="break" />}
        {!!data.phone &&
          <span className="iconized">
            <IconDeviceLandlinePhone />
            <a href={`tel:${data.phone}`}>{data.phone}</a>
          </span>
        }
        {!!data.fax &&
          <span className="iconized">
            <IconPrinter />
            {data.fax}
          </span>
        }
        {!!data.email &&
          <span className="iconized">
            <IconAt />
            <a href={`mailto:${data.email}`}>{data.email}</a>
          </span>
        }
        {!!data.website &&
          <span className="iconized">
            <IconWorldWww />
            <a href={data.website} target="_blank">{data.website}</a>
          </span>
        }
      </address>

      {!!onContact &&
        <div className="cbox-footer">
          <Button className="small bolder"
                  onClick={() => onContact(data)}>
            {intl.formatMessage(agencyTranslations.contact)}
          </Button>
        </div>
      }

    </div>);

}

export default AgencyContactBox;
