import React from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { IconStar } from "@tabler/icons-react";
import "./all-reviews.scss";

const ALL_ARTICLE_REVIEWS_GQL_DATA = gql`
  query GetAllReviews($articleId: ID!) {
    reviews: bookingAccommodationReviews(articleId: $articleId) {
      comments { reviewedAt customerName averageRate comment answer { answeredAt comment } }
    }
  }
`;

type AllReviewsProps = {
  articleId: string;
}

const AllReviews = ({ articleId }: AllReviewsProps) => {

  const intl = useIntl();

  const { data, loading } = useQuery(ALL_ARTICLE_REVIEWS_GQL_DATA, {
    variables: { articleId }
  });

  if (loading)
    return (
      <div className="rla-reviews">
        {Array.from({ length: 2 }).map((_, i) => (
          <div key={i}
               className="one-comment">
            <div className="client">
              <Skeleton containerClassName="name skeleton" width="75%" />
              <Skeleton containerClassName="date" width="35%" />
            </div>
            <div className="comment">
              <Skeleton count={4} width="100%" />
              <Skeleton width="45%" />
            </div>
          </div>
        ))}
      </div>);

  return (
    <div className="rla-reviews">
      {data.reviews.comments.map((data, i) => (
        <div key={i}
             id={`comment-${i}`}
             className="one-comment">

          <div className="client">
            <div className="name">
              {data.customerName}
              <span className="rate">
              <IconStar/>
                {intl.formatNumber(data.averageRate, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
            </span>
            </div>
            <div className="date">
              {intl.formatDate(moment(data.reviewedAt).toDate(), { month: "long", year: "numeric" })}
            </div>
          </div>

          <p className="comment">{data.comment}</p>

          {!!data.answer &&
            <div className="answer">
              <div className="agency">
                <span>{intl.formatMessage({ id: "booking-reviews-agency-answer", defaultMessage: "Réponse" })}</span>
                {intl.formatDate(moment(data.answer.answeredAt).toDate(), { month: "long", year: "numeric" })}
              </div>
              <p className="comment">{data.answer.comment}</p>
            </div>
          }

        </div>
      ))}
    </div>);

}

export default AllReviews;
