import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IconCheck, IconX } from "@tabler/icons-react";
import { classNames, useIsMounted } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { FilterSearchFeaturesCriteria, ObjectType } from "../../models/search";
import { featureTranslations } from "../../i18n/sharable-defs";
import "./filter-content.scss";

const defineMaximizeNumberValue = (val: number | undefined, max: number) =>
  !val ? val : Math.min(val, max);

type FilterAnyProps<T> = {
  value?: T,
  onValueChange: (value: T | undefined) => void;
}

type FilterObjectTypesProps = FilterAnyProps<ObjectType[]> & { typesList: ObjectType[] }
type FilterRoomsProps = FilterAnyProps<number> & { max?: number }

export const FilterObjectTypes = (
  {
    typesList,
    value = [],
    onValueChange = () => void 0
  }: FilterObjectTypesProps) => {

  const { transLib } = useLocaleFormatter();

  const onCheckboxChange = (val: ObjectType, e: ChangeEvent<HTMLInputElement>) => {
    const changes = [ ...value ];
    const search = changes.find(cv => cv.id === val.id);
    if (!!search && !e.target.checked) changes.splice(changes.indexOf(search), 1);
    if (!search && e.target.checked) changes.push(val);
    onValueChange(changes.length === 0 ? undefined : changes);
  }

  if (typesList.length === 0)
    return null;

  return (
    <div className="filter-two-cols">
      {typesList.map((t, i) => (
        <label key={i} htmlFor={t.name} className="a-filter-check">
          <input type="checkbox" id={t.name}
                 checked={!!value.find(v => v.id === t.id) || false}
                 onChange={(e) => onCheckboxChange(t, e)} />
          <span tabIndex={0} className="checkmark"><IconCheck /></span>
          <span>{transLib.match("objectTypes", t.id)}</span>
        </label>)
      )}
    </div>);

};

export const FilterRooms = (
  {
    max = 10,
    value: propsValue,
    onValueChange = () => void 0
  }: FilterRoomsProps) => {

  const isMounted = useIsMounted();

  const [ value, setValue ] = useState<number | undefined>(defineMaximizeNumberValue(propsValue, max));

  useEffect(() => {
    if (!isMounted) return;
    setValue(defineMaximizeNumberValue(propsValue, max));
  }, [ propsValue ]);

  useEffect(() => {
    if (!isMounted) return;
    onValueChange(value);
  }, [ value ]);

  return (
    <div className="scrollable">
      <div className="filter-list-choice">
        <button type="button"
                className={classNames("all", { selected: !value })}
                onClick={() => onValueChange(undefined)}>Tout</button>
        {Array.from({ length: max }).map((_, i) => i + 1 !== max
          ? <button key={i}
                    type="button"
                    className={classNames({ selected: value === i + 1})}
                    onClick={() => setValue(i + 1)}>{i + 1}</button>
          : <button key={i}
                    type="button"
                    className={classNames({ selected: value === i + 1})}
                    onClick={() => setValue(i + 1)}>{i + 1}+</button>
        )}
      </div>
    </div>);

}

export const FilterFeatures = (
  {
    value = {},
    onValueChange = () => void 0
  }: FilterAnyProps<FilterSearchFeaturesCriteria>) => {

  const intl = useIntl();

  const features = [ "wifi", "tv", "washingMachine", "dishwasher", "fireplace", "parking", "balcony", "terrace", "pool", "sauna", "smoker", "pet", "disabledPerson" ];
  const reversedLogic = [ "smoker" ];

  const comparableValue = useCallback((feature: string): boolean | undefined => {
    if (!Object.keys(value).includes(feature)) return undefined;
    return reversedLogic.includes(feature) ? !value[feature] : value[feature];
  }, [ features, reversedLogic, value ]);

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    let changes = { ...value };
    const comparer = comparableValue(e.target.id);
    const newValue = reversedLogic.includes(e.target.id) ? !e.target.checked : e.target.checked;
    if (comparer !== e.target.checked) {
      if (typeof comparer === "boolean" && !e.target.checked) delete changes[e.target.id];
      if (e.target.checked) changes = { ...changes, [e.target.id]: newValue };
    }
    onValueChange(Object.keys(changes).length === 0 ? undefined : changes);
  }

  return (
    <div className="filter-two-cols">
      {features.map((t, i) => (
        <label key={i} htmlFor={t} className="a-filter-check">
          <input type="checkbox" id={t}
                 checked={comparableValue(t) ?? false}
                 onChange={onCheckboxChange} />
          <span tabIndex={0} className="checkmark"><IconCheck/></span>
          <span>{intl.formatMessage(featureTranslations[t])}</span>
        </label>)
      )}
    </div>
  );

}

export const FilterName = (
  {
    value = undefined,
    onValueChange = () => void 0
  }: FilterAnyProps<string | undefined>) => {

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "" ? undefined : e.target.value;
    onValueChange(value);
  };

  return (
    <div className="filter-input">
      <input type="text" value={value || ""} onChange={onChange}/>
      {!!value &&
        <button type="button" className="resetter" onClick={() => onValueChange(undefined)}><IconX /></button>
      }
    </div>);

}
