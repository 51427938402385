import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  InputHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from "react";
import { useIntl } from "react-intl";
import { classNames } from "@ct-react/core";
import "./minimals.scss";
import { useLocaleFormatter } from "@ct-react/locale";

type LinkProps = PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>;
type ButtonProps = PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>;
type InputProps = PropsWithChildren<InputHTMLAttributes<HTMLInputElement>>;

type ClampTextProps = {
  text: string;
  maxChar?: number;
  expandable?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ className, ...props }: ButtonProps, ref) =>
  <button ref={ref}
          className={classNames("r-btn", className)}
          {...props} />);

export const LinkAsButton = forwardRef<HTMLAnchorElement, LinkProps>(({ className, ...props }: LinkProps, ref) =>
  <a ref={ref}
     className={classNames("r-btn", className)}
    {...props} />);

export const ClampText = (
  {
    text,
    maxChar = 200,
    expandable = true
  }: ClampTextProps) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();
  const [ moreable, setMoreable ] = useState<boolean>(false);
  const [ expanded, setExpanded ] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(false);
    setMoreable(expandable && text.length > maxChar);
  }, [ text, maxChar, expandable ]);

  const display = useMemo(() => {
    if (text.length <= maxChar || expanded)
      return text;
    return print.ellipsis(text, maxChar);
  }, [ expandable, expanded ]);

  return (
    <>
      {display}
      {moreable &&
        <Button type="button"
                className="link small readmore-inline"
                onClick={() => setExpanded(prev => !prev)}>
          {expanded
            ? intl.formatMessage({ id: "generics-more-close", defaultMessage: "Masquer" })
            : intl.formatMessage({ id: "generics-more-open", defaultMessage: "En savoir plus" })
          }
        </Button>
      }
    </>);

}
