import { Moment } from "moment";
import { Period } from "@ct-react/core";

export type PaginationDirection = "before" | "after";
export type FlexBookingDuration = "WEEKEND" | "WEEK";

export type Resort = {
  id: number;
  slug?: string;
  name?: string;
}

export type ObjectType = {
  id: number;
  name?: string;
}

export type RangeBookingConfig = {
  period: Period<Moment>;
  dayMargin?: number;
}

export type FlexBookingConfig = {
  duration: FlexBookingDuration;
  intoMonths: Moment[];
}

export type BookingConfig = RangeBookingConfig | FlexBookingConfig;

export const isFlexBookingConfig = (val?: BookingConfig): val is FlexBookingConfig =>
  !!val && "duration" in val;

export type FilterSearchFeaturesCriteria = {
  wifi?: boolean;
  tv?: boolean;
  washingMachine?: boolean;
  dishwasher?: boolean;
  fireplace?: boolean;
  parking?: boolean;
  balcony?: boolean;
  terrace?: boolean;
  pool?: boolean;
  sauna?: boolean;
  smoker?: boolean;
  pet?: boolean;
  disabledPerson?: boolean;
}

export type MainSearchCriteria = {
  region?: Resort;
  guests?: number;
  period?: BookingConfig;
}

export type FilterSearchCriteria = {
  objectTypes?: ObjectType[];
  rooms?: number;
  bedrooms?: number;
  bathrooms?: number;
  features?: FilterSearchFeaturesCriteria;
  name?: string;
}

export type SearchQuery = {
  agency?: string;
} & FilterSearchCriteria & MainSearchCriteria;

export type SearchViewConfig = {
  mapView: boolean;
}

export type SearchPagination = {
  direction: PaginationDirection
  cursor?: string;
};

export type SearchContext = {
  query: SearchQuery;
  pagination: SearchPagination;
  viewConfig: SearchViewConfig;
}
