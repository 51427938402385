import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { IconCalendar, IconX } from "@tabler/icons-react";
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs
} from "@floating-ui/react";
import { AnyPeriod, castPeriod, classNames, isValidPeriod, Period, Periodable, useIsMounted } from "@ct-react/core";
import { RangePickerController } from "@ct-react/calendar";
import { useLocaleFormatter } from "@ct-react/locale";
import { bookingTranslations } from "../../../i18n/sharable-defs";
import {
  BookingConfig,
  FlexBookingConfig,
  FlexBookingDuration,
  isFlexBookingConfig,
  RangeBookingConfig
} from "../../../models/search";
import {
  MainSearchInputProps,
  SearchInputDefinitionProps,
  SearchInputSelectionProps,
  SearchInputViewProps
} from "./common";
import "./common.scss";
import "./period-input.scss"
import { usePickerLabelize } from "../../../hooks/generic";

const transDefs = defineMessages({
  any: { id: "search-form-period-input-default", defaultMessage: "N'importe quand" },
  flexState: { id: "search-form-period-flex-state", defaultMessage: "{duration}{count, plural, =0 {} other { en {months}}}" },
  range: { id: "search-form-period-range-view-title", defaultMessage: "Dates fixes" },
  flex: { id: "search-form-period-flex-view-title", defaultMessage: "Dates flexibles" },
  rangeExact: { id: "search-form-range-opt-no-margin", defaultMessage: "Dates exactes" },
  rangeDayMargin: { id: "search-form-range-opt-margin", defaultMessage: "{val, plural, =1 {± # jour} other {± # jours}}" },
  flexLabel: { id: "search-form-period-flex-duration-title", defaultMessage: "Combien de temps ?" },
  flexWeekend: { id: "search-form-period-flex-duration-weekend-option", defaultMessage: "Un week-end" },
  flexWeek: { id: "search-form-period-flex-duration-week-option", defaultMessage: "Une semaine" },
  flexDurationLabel: { id: "search-form-period-flex-months-title", defaultMessage: "Quand ?" }
});

type PickerViewType = "range" | "flex";
type InputExtraProps = { monthsList: Moment[] };
type InputViewProps = SearchInputDefinitionProps & InputExtraProps & SearchInputViewProps<BookingConfig>;
type InputSelectionProps = InputExtraProps & { mobileView: boolean } & SearchInputSelectionProps<BookingConfig>;
type MainSearchPeriodInputProps = MainSearchInputProps<BookingConfig> & InputExtraProps;

export const periodFormattedState = (intl: IntlShape, printFunc: (value: AnyPeriod) => string, value: BookingConfig | undefined) => {
  if (!value) return intl.formatMessage(transDefs.any);
  if (isFlexBookingConfig(value))
    return intl.formatMessage(transDefs.flexState, {
      ...(value.duration === "WEEKEND") && { duration: intl.formatMessage(transDefs.flexWeekend)},
      ...(value.duration === "WEEK") && { duration: intl.formatMessage(transDefs.flexWeek)},
      count: value.intoMonths?.length || 0,
      months: value.intoMonths?.map(m => moment(m, "YYYY-MM"))
        .sort((a,b) => a.valueOf() - b.valueOf()).map(m => intl.formatDate(m.toDate(), { month: "short" })).join(", ") || []
    }).toString();
  return `${printFunc(value.period)}${!!value.dayMargin ? ` ±${value.dayMargin}` : ""}`;
}

const InputSelection = (
  {
    mobileView,
    monthsList,
    value,
    onSelection,
  }: InputSelectionProps) => {

  const isMounted = useIsMounted();
  const intl = useIntl();
  const calendarLabelize = usePickerLabelize();

  const initedView = isFlexBookingConfig(value) ? "flex" : "range";
  const initedPickerRange = isFlexBookingConfig(value) ? {} : (!!value?.period ?  castPeriod(value.period): {});
  const initedRangePeriod = (isFlexBookingConfig(value) || !value?.period) ? undefined : castPeriod(value.period);
  const initedRangeMargin = isFlexBookingConfig(value) ? undefined : value?.dayMargin;
  const initedFlexDuration = isFlexBookingConfig(value) ? value.duration: undefined;
  const initedFlexMonths = isFlexBookingConfig(value) ? value.intoMonths : undefined;

  const [ activeView, setActiveView ] = useState<PickerViewType>(initedView);
  const [ pickerRange, setPickerRange ] = useState<Periodable<Moment>>(initedPickerRange);
  const [ pickerFocus, setPickerFocus ] = useState<any>("start");
  const [ rangePeriod, setRangePeriod ] = useState<Period<Moment> | undefined>(initedRangePeriod);
  const [ rangeMargin, setRangeMargin ] = useState<number | undefined>(initedRangeMargin);
  const [ flexDuration, setFlexDuration ] = useState<FlexBookingDuration>(initedFlexDuration || "WEEK");
  const [ flexMonths, setFlexMonths ] = useState<Moment[]>(initedFlexMonths || [ moment().startOf("months") ]);

  // sync period and picker range only when period is fully valid
  useEffect(() => {
    if (pickerFocus !== null || !isValidPeriod(pickerRange)) return;
    setRangePeriod(pickerRange);
  }, [ pickerFocus ]);

  // trigger period change on view switch and any params changes
  useEffect(() => {
    if (!isMounted) return;
    if (activeView === "flex") {
      onSelection({
        duration: flexDuration,
        intoMonths: flexMonths
      } as FlexBookingConfig);
    } else {
      if (!rangePeriod) onSelection(undefined);
      else onSelection({
        period: rangePeriod,
        dayMargin: rangeMargin
      } as RangeBookingConfig)
    }
  }, [ activeView, rangePeriod, rangeMargin, flexDuration, JSON.stringify(flexMonths) ]);

  // dom interactions

  const onMonthClick = (m: Moment) => {
    const sameMonth = flexMonths.find(fm => fm.isSame(m, "month"));
    if (!!sameMonth && flexMonths.length > 1) setFlexMonths(flexMonths.filter(fm => fm !== sameMonth));
    if (!sameMonth) setFlexMonths([ ...(flexMonths || []), m ]);
  };

  // rendering

  const activeClasses = (comparer: PickerViewType) => classNames({ active: comparer === activeView });
  const contentClasses = classNames("period-input-choice content", activeView);
  const marginClasses = (comparer?: number) => classNames("r-btn-choice", { active: rangeMargin === comparer });
  const durationClasses = (comparer: FlexBookingDuration) => classNames("r-btn-choice", { active: flexDuration === comparer});
  const monthClasses = (comparer: Moment) => classNames("r-btn-choice", "month", { active: flexMonths.some(fm => fm.isSame(comparer, "month"))});

  return (
    <>

      <div className="period-input-choice header">
        <button type="button"
                className={activeClasses("range")}
                onClick={() => setActiveView("range")}>
          {intl.formatMessage(transDefs.range)}
        </button>
        <button type="button"
                className={activeClasses("flex")}
                onClick={() => setActiveView("flex")}>
          {intl.formatMessage(transDefs.flex)}
        </button>
      </div>

      <div className={contentClasses}>

        {activeView === "range" &&
          <>
            <RangePickerController numberOfMonths={mobileView ? 1 : 2}
                                   orientation={mobileView ? "vertical" : "horizontal"}
                                   minDate={moment()}
                                   maxDate={moment().add(1, "year").endOf("month")}
                                   firstDayOfWeek={1}
                                   labelize={calendarLabelize}
                                   selectedRange={pickerRange}
                                   pickFocus={pickerFocus}
                                   onSelectedRangeChange={setPickerRange}
                                   onPickFocusChange={setPickerFocus} />
            <div className="scrollable">
              <div className="r-choice-wrapper">
                <button type="button"
                        className={marginClasses(undefined)}
                        onClick={() => setRangeMargin(undefined)}>
                  {intl.formatMessage(transDefs.range)}
                </button>
                {[ 1, 2, 3, 7].map((m, i) =>
                  <button key={i}
                          type="button"
                          className={marginClasses(m)}
                          onClick={() => setRangeMargin(m)}>
                    {intl.formatMessage(transDefs.rangeDayMargin, { val: m })}
                  </button>
                )}
              </div>
            </div>
          </>
        }

        {activeView === "flex" &&
          <>
            <div className="r-flex-group">
              <span>{intl.formatMessage(transDefs.flexLabel)}</span>
              <div className="r-choice-wrapper">
                <button type="button"
                        className={durationClasses("WEEKEND")}
                        onClick={() => setFlexDuration("WEEKEND")}>
                  {intl.formatMessage(transDefs.flexWeekend)}
                </button>
                <button type="button"
                        className={durationClasses("WEEK")}
                        onClick={() => setFlexDuration("WEEK")}>
                  {intl.formatMessage(transDefs.flexWeek)}
                </button>
              </div>
            </div>

            <div className="r-flex-group">
              <span>{intl.formatMessage(transDefs.flexDurationLabel)}</span>
              <div className="scrollable">
                <div className="r-choice-wrapper">
                  {monthsList.map((m, i) =>
                    <button key={i}
                            type="button"
                            className={monthClasses(m)}
                            onClick={() => onMonthClick(m)}>
                      <IconCalendar />
                      {intl.formatDate(m.toDate(), { month: "long" })}
                      <br />
                      {intl.formatDate(m.toDate(), { year: "numeric" })}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        }

      </div>

    </>);
}

const InputMobileView = (
  {
    name,
    open,
    toggle,
    monthsList,
    value,
    formattedValue,
    onValueChange
  }: InputViewProps) => {

  const intl = useIntl();

  // dom interactions

  const onSelection = (val: BookingConfig | undefined, e?: MouseEvent) => {
    onValueChange(val);
    e?.stopPropagation();
  }

  const onReset = (e: MouseEvent) => {
    onValueChange(undefined);
    e.stopPropagation();
  }

  // rendering

  const wrapperClasses = classNames("input-wrap", name, { selection: open });

  const editionRendering = useMemo(() => (<>
    <h2 onClick={() => toggle(name, false)}>{intl.formatMessage(bookingTranslations.period)}</h2>
    <InputSelection mobileView={true} {...{ monthsList, value, onSelection }} />
  </>), [ monthsList, value ]);

  const resumeRendering = useMemo(() => (
    <>
      <div className="input-label ellipsed">{intl.formatMessage(bookingTranslations.period)}</div>
      <div className={classNames("input-val-state", { empty: !value })}>
        <span className="label ellisped">{intl.formatMessage(bookingTranslations.periodPlaceholder)}</span>
        <span className="value ellipsed">{formattedValue || intl.formatMessage(transDefs.any)}</span>
      </div>
      {!!value &&
        <button type="button" className="resetter" onClick={onReset}><IconX /></button>
      }
    </>
  ), [ value, formattedValue ]);

  return (
    <div tabIndex={0}
         className={wrapperClasses} onClick={() => !open && toggle(name, true)}>
      {open ? editionRendering : resumeRendering}
    </div>);

}

const InputDesktopView = (
  {
    name,
    open,
    toggle,
    monthsList,
    value,
    formattedValue,
    onValueChange
  }: InputViewProps) => {

  const intl = useIntl();

  // picker floating display

  const { x, y, reference, floating, strategy, context } = useFloating({
    open: open,
    onOpenChange: isOpen => toggle(name, isOpen),
    whileElementsMounted: autoUpdate,
    placement: "bottom",
    middleware: [ offset(8) ]
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { enabled: open, ancestorScroll: true })
  ]);

  // dom interaction

  const onSelection = (val: BookingConfig | undefined, _?: MouseEvent) => {
    onValueChange(val);
  }

  // rendering

  const inputRef = useMergeRefs([ reference ]);
  const inputWrapperClasses = classNames("input-wrap", name);
  const pickerClasses = classNames("rf-searcher-input-dropdown", name);

  return (
    <>
      <div className={inputWrapperClasses}>
        <div ref={inputRef}
             {...getReferenceProps()}
             tabIndex={0}
             className="input-content inner-space">
          <IconCalendar />
          <div className="input-val-wrap">
            <div className="input-label ellipsed">{intl.formatMessage(bookingTranslations.period)}</div>
            <div className={classNames("input-val-state", { empty: !formattedValue })}>
              {!!formattedValue && <span className="value ellipsed">{formattedValue}</span>}
              <span className="label ellipsed">{intl.formatMessage(transDefs.any)}</span>
            </div>
          </div>
        </div>
        {!!formattedValue &&
          <button type="button" className="resetter" onClick={() => onValueChange(undefined)}><IconX /></button>
        }
      </div>
      <FloatingPortal>
        {open &&
          <FloatingFocusManager context={context}>
            <div ref={floating}
                 {...getFloatingProps()}
                 className={pickerClasses}
                 style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}>
              <InputSelection mobileView={false} {...{ monthsList, value, onSelection }} />
            </div>
          </FloatingFocusManager>
        }
      </FloatingPortal>
    </>);

}

const MainSearchPeriodInput = (
  {
    name,
    open,
    toggle,
    mobileView,
    monthsList,
    value: initedValue,
    onValueChange
  }: MainSearchPeriodInputProps) => {

  const isMounted = useIsMounted();
  const intl = useIntl();
  const { print } = useLocaleFormatter();

  // component states

  const [ value, setValue ] = useState<BookingConfig | undefined>(initedValue);

  useEffect(() => {
    if (!isMounted) return;
    onValueChange(value);
  }, [ value ]);

  const formattedValue = useMemo(() => {
    if (!value) return "";
    return periodFormattedState(intl, print.period, value);
  }, [ value ]);

  return mobileView
    ? <InputMobileView name={name}
                       open={open}
                       toggle={toggle}
                       monthsList={monthsList}
                       value={value}
                       formattedValue={formattedValue}
                       onValueChange={setValue} />
    : <InputDesktopView name={name}
                        open={open}
                        toggle={toggle}
                        monthsList={monthsList}
                        value={value}
                        formattedValue={formattedValue}
                        onValueChange={setValue} />
  ;

}

export default MainSearchPeriodInput;
