import React, { ReactNode } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { IconLoader3 } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { useLocaleFormatter, useLocaleNavigate } from "@ct-react/locale";
import { globalTranslations } from "../../i18n/sharable-defs";
import { DataProps } from "../../tools/components";
import { CartItem as CartItemModel } from "../../models/cart";
import CartItemPrice, { CartItemCharge } from "./cart-price";
import { Asset, Ratio } from "../../models/medias";
import { Button } from "../common/minimals";
import RatioImage from "../common/ratio-image";
import { CartItemCountdown } from "../../code-splitting/client-only-ui";
import LastMinuteWarning from "./last-minute-warning";
import "./cart-item.scss";

type CartItemProps = DataProps<CartItemModel> & {
  nextStep?: ReactNode;
  actionRemoving?: boolean;
  onRemove?: (id: string) => void;
}

const CartItem = (
  {
    className,
    data,
    nextStep,
    actionRemoving = false,
    onRemove = () => void 0,
  }: CartItemProps) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();
  const navigate = useLocaleNavigate();

  const wrapperClasses = classNames("cart-item", className);
  const priceCollWrapperClasses = classNames("cart-item-price", { "with-action": !!nextStep });

  return (
    <article className={wrapperClasses}>
      <h1>{data.title}</h1>
      <span className="desc">{data.description}</span>
      <div className="cart-item-details">

        <div className="cart-item-resume">
          {!!data.image && <RatioImage images={[ { url: data.image } as Asset ]} ratio={Ratio.SIXTEEN_NINE} alt={data.title} />}
          <div className="period">
              <span className="label">
                {intl.formatMessage({
                  id: "cart-item-book-label",
                  defaultMessage: "Dates réservées"
                })}
              </span>
            <span className="value">{print.period(data.period, true)}</span>
          </div>
          <div className="actions">
            <Button type="button"
                    className={classNames("secondary bolder loadable-keeping-size", { loading: actionRemoving })}
                    onClick={() => onRemove(data.itemId)}>
              <span className="text">{intl.formatMessage(globalTranslations.cancel)}</span>
              <div className="loader"><IconLoader3 /></div>
            </Button>
          </div>
          <div className="cart-item-lifetime">
            <CartItemCountdown expirationDate={moment(data.expirationDate)}
                               onRefresh={() => navigate(0)} />
          </div>
        </div>

        <div className={priceCollWrapperClasses}>
          <CartItemPrice data={data.price} />
          {!!data.downPayment.providerCharge && <CartItemCharge data={data.downPayment} />}
          {data.downPayment.rate === 1 && <LastMinuteWarning />}
          {nextStep}
        </div>

      </div>
    </article>);

}

export default CartItem;
