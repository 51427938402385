import React from "react";
import { Helmet } from "react-helmet-async";
import { defineMessages, useIntl } from "react-intl";
import { IconChevronsRight, IconError404 } from "@tabler/icons-react";
import { LocaleLink } from "@ct-react/locale";
import Wrapper from "../components/layout/wrapper";
import "./not-found.scss";

const transDefs = defineMessages({
  title: { id: "error-title", defaultMessage: "Nous ne trouvons pas la page que vous recherchez." },
  link: { id: "error-link", defaultMessage: "Retourner à la page d'accueil" }
});

export default () => {

  const intl = useIntl();

  return (
    <div className="rl-error">
      <Helmet>
        <title>{intl.formatMessage(transDefs.title)}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Wrapper.Centered as="main">
        <IconError404 />
        <div className="error-redirect">
          <h1>{intl.formatMessage(transDefs.title)}
          </h1>
          <div className="link">
            {intl.formatMessage(transDefs.link)}
            <LocaleLink to="/">
              <IconChevronsRight />
            </LocaleLink>
          </div>
        </div>
      </Wrapper.Centered>
    </div>);

}
