import loadable from "@loadable/component";

export const CartItemCountdown = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */ "./cart-item-countdown"),
  { ssr: false }
);

export const ProtectedRoute = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */ "./protected-route"),
  { ssr: false }
);

export const CookieBanner = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */ "./cookie-banner"),
  { ssr: false }
);

export const CookieProvider = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */ "./cookie-provider"),
  { ssr: false }
);

export const NotFoundWithoutLocalePrefix = loadable(
  () => import(/* webpackChunkName: "client-only-ui" */ "./not-found"),
  { ssr: false }
);
