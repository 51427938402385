import {gql} from "@apollo/client";

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFields on Address {
    street
    streetNumber
    streetComplement
    zip
    city
    country
  }
`;

export const ARTICLE_SUMMARY_FRAGMENT = gql`
  fragment ArticleSummaryFields on RentalAccommodationArticle {
    id
    slug
    title { value }
    images { main aspectRatio assets { url breakpoint } }
    features { rooms maxGuests bedrooms }
    discounts {
      logic
      period
      type
      fixedValue
      percentageValue
    }
    merchant { id slug }
  }
`;

export const BOOKING_SUGGESTION_FRAGMENT = gql`
  fragment BookingSuggestionFields on AccommodationBookingOption {
    period
    bookable
    priceOnDemand
    price {
      amount
      detailed {
        bare
        discount
        providerCharge { amount adaptive }
      }
    }
  }
`;

export const ALL_FEATURES_FRAGMENT = gql`
  fragment AllFeaturesFields on RentalAccommodationFeatures {
    isPetCompliant
    isDisabledPersonCompliant
    withBalcony
    withTerrace
    withParking
    withWIFI
    withTV
    withLift
    withDishwasher
    withWashingMachine
    withSauna
    withPool
    withFireplace
    isSmokerCompliant
    withSmokeAlarm
    withCarbonAlarm
  }
`;

export const COST_DESCRIPTIONS_FRAGMENT = gql`
  fragment AllCostDescriptionsFields on AccommodationBookingCostDescriptions {
    cleaning { matchLang value }
    bed { matchLang value }
    kitchen { matchLang value }
    bath { matchLang value}
    parking { matchLang value }
    pet { matchLang value }
    benefits { matchLang value }
    tax { matchLang value }
    deposit { matchLang value }
    charges { matchLang value }
    cancellation { matchLang value }
  }
`;

export const MERCHANT_CONTACT_FRAGMENT = (typeName: string) => gql`
  fragment ${typeName}Fields on ${typeName} {
    id
    slug
    name
    nameComplement
    address { ...AddressFields }
    phone
    fax
    email
    website
    logo
    lead { value }
  }
`;

export const CART_ITEM_FRAGMENT = gql`
  fragment CartItemFields on RentalAccommodationCartItem {
    itemId
    title
    description
    image
    period
    price { bare discount options { type amount } amount }
    downPayment { rate providerCharge }
    expirationDate
  }
`;
