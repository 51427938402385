import React from "react";
import { useIntl } from "react-intl";
import { IconInfoSquareRoundedFilled } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { FormattedBookingOption, isBookingPrice, isBookingPriceWithCharge } from "@ct-react/calendar";
import { bookingTranslations } from "../../../i18n/sharable-defs";
import { DataProps } from "../../../tools/components";
import Price from "../../common/price";
import Tooltip, { InnerTooltip } from "../../common/tooltip";
import "./book-price-resume.scss";

const BookPriceResume = (
  {
    className,
    data
  }: DataProps<FormattedBookingOption>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const wrapperClasses = classNames("rla-book-price-resume", className);

  return (
    <div className={wrapperClasses}>

      {(isBookingPrice(data.price)) &&
        <>
          <dl className="price-list">
            <dt>
              {intl.formatMessage(bookingTranslations.nightsPeriod, {
                nightsCount: data.period.end.diff(data.period.start, "day")
              })}
            </dt>
            <dd><Price data={data.price}/></dd>
          </dl>
          {isBookingPriceWithCharge(data.price) &&
            <dl className="price-list">
              <dt>
                <Tooltip placement="bottom">
                  <InnerTooltip.Trigger as="span" className="tooltip-more">
                    {intl.formatMessage(bookingTranslations.customerCharge)}
                    <IconInfoSquareRoundedFilled />
                  </InnerTooltip.Trigger>
                  <InnerTooltip.Content>
                    <p>{intl.formatMessage(bookingTranslations.customerChargeTooltip)}</p>
                    {isBookingPriceWithCharge(data.price, true) &&
                      <p>{intl.formatMessage(bookingTranslations.customerChargeAdaptive)}</p>
                    }
                  </InnerTooltip.Content>
                </Tooltip>
              </dt>
              <dd>{intl.formatMessage(bookingTranslations.customerChargePrice, {
                adaptive: isBookingPriceWithCharge(data.price, true),
                amount: print.price(data.price.detailed.providerCharge!.amount)
              })
              }</dd>
            </dl>
          }
        </>
      }
      <dl className="price-list total">
        <dt>{intl.formatMessage(bookingTranslations.total)}</dt>
        <dd>
          {data.price === "onDemand"
            ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
            : <Price data={data.price} total={true} />
          }
        </dd>
      </dl>

    </div>);

};

export default BookPriceResume;
