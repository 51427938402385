import React from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import {
  IconDeviceTv,
  IconDeviceTvOff,
  IconDisabled,
  IconDisabledOff,
  IconFence,
  IconFenceOff,
  IconFlame,
  IconFlameOff,
  IconParking,
  IconParkingOff,
  IconPaw,
  IconPool,
  IconSmoking,
  IconSmokingNo,
  IconWashMachine,
  IconWifi,
  IconWifiOff
} from "@tabler/icons-react";
import { classNames, useAppConfig } from "@ct-react/core";
import { CustomViewType } from "@shared/models/app-context";
import { featureTranslations } from "../../../i18n/sharable-defs";
import { SkeletonableProps } from "../../../tools/components";
import SaunaSvg from "../../../assets/componable-svgs/feat-sauna.svg";
import DishwasherSvg from "../../../assets/componable-svgs/feat-dishwasher.svg";
import BalconySvg from "../../../assets/componable-svgs/feat-balcony.svg";
import SmokeAlarmSvg from "../../../assets/componable-svgs/feat-smoke-alarm.svg";
import CarbonAlarmSvg from "../../../assets/componable-svgs/feat-carbon-alarm.svg";
import SmokeAlarmOffSvg from "../../../assets/componable-svgs/feat-smoke-alarm-off.svg";
import CarbonAlarmOffSvg from "../../../assets/componable-svgs/feat-carbon-alarm-off.svg";
import "./full-features.scss"

interface FeaturesData {
  isPetCompliant: boolean;
  isDisabledPersonCompliant: boolean;
  withBalcony: boolean;
  withTerrace: boolean;
  withParking: boolean;
  withWIFI: boolean;
  withTV: boolean;
  withLift: boolean;
  withDishwasher: boolean;
  withWashingMachine: boolean;
  withSauna: boolean;
  withPool: boolean;
  withFireplace: boolean;
  isSmokerCompliant: boolean;
  withSmokeAlarm: boolean;
  withCarbonAlarm: boolean;
}

type FullFeaturesProps = SkeletonableProps<FeaturesData>;

const DisplayFullFeatures = (content: FeaturesData) => {

  const intl = useIntl();
  const { options: { customView } } = useAppConfig();

  const toClasses = (feature: boolean) => classNames("a-feature", {
    "yes": feature,
    "no": !feature,
    "mask": !feature && customView === CustomViewType.HOTEL
  })

  return (
    <>
      <span className={toClasses(content!.isPetCompliant)}>
        <IconPaw/>
        {intl.formatMessage(featureTranslations.pet)}
      </span>
      <span className={toClasses(content!.isDisabledPersonCompliant)}>
        {content!.isDisabledPersonCompliant ? <IconDisabled /> : <IconDisabledOff />}
        {intl.formatMessage(featureTranslations.disabledPerson)}
      </span>
      <span className={toClasses(content!.withBalcony)}>
        <BalconySvg className="custom"/>
        {intl.formatMessage(featureTranslations.balcony)}
      </span>
      <span className={toClasses(content!.withTerrace)}>
        {content!.withTerrace ? <IconFence/> : <IconFenceOff/>}
        {intl.formatMessage(featureTranslations.terrace)}
      </span>
      <span className={toClasses(content!.withParking)}>
        {content!.withParking ? <IconParking/> : <IconParkingOff/>}
        {intl.formatMessage(featureTranslations.parking)}
      </span>
      <span className={toClasses(content!.withWIFI)}>
        {content!.withWIFI ? <IconWifi/> : <IconWifiOff/>}
        {intl.formatMessage(featureTranslations.wifi)}
      </span>
      <span className={toClasses(content!.withTV)}>
        {content!.withTV ? <IconDeviceTv/> : <IconDeviceTvOff/>}
        {intl.formatMessage(featureTranslations.tv)}
      </span>
      <span className={toClasses(content!.withWashingMachine)}>
        <IconWashMachine/>
        {intl.formatMessage(featureTranslations.washingMachine)}
      </span>
      <span className={toClasses(content!.withDishwasher)}>
        <DishwasherSvg className="custom"/>
        {intl.formatMessage(featureTranslations.dishwasher)}
      </span>
      <span className={toClasses(content!.withPool)}>
        <IconPool/>
        {intl.formatMessage(featureTranslations.pool)}
      </span>
      <span className={toClasses(content!.withSauna)}>
        <SaunaSvg className="custom"/>
        {intl.formatMessage(featureTranslations.sauna)}
        </span>
      <span className={toClasses(content!.withFireplace)}>
        {content!.withFireplace ? <IconFlame/> : <IconFlameOff/>}
        {intl.formatMessage(featureTranslations.fireplace)}
      </span>
      <span className={toClasses(!content!.isSmokerCompliant)}>
        {content!.isSmokerCompliant ? <IconSmoking/> : <IconSmokingNo/>}
        {intl.formatMessage(featureTranslations.smoker)}
      </span>
      <span className={toClasses(content!.withSmokeAlarm)}>
        {content!.withSmokeAlarm ? <SmokeAlarmSvg className="custom"/> : <SmokeAlarmOffSvg className="custom"/>}
        {intl.formatMessage(featureTranslations.smokerAlarm)}
      </span>
      <span className={toClasses(content!.withCarbonAlarm)}>
        {content!.withCarbonAlarm ? <CarbonAlarmSvg className="custom"/> : <CarbonAlarmOffSvg className="custom"/>}
        {intl.formatMessage(featureTranslations.carbonAlarm)}
      </span>
    </>);

}

const FullFeatures = (
  {
    loading,
    className,
    data
  }: FullFeaturesProps) => {

  const intl = useIntl();

  return (
    <div className={classNames("rla-features", className)}>
      <h2>
        {!loading
          ? intl.formatMessage({
            id: "booking-features-title",
            defaultMessage: "Ce que propose ce logement"
          })
          : <Skeleton inline={true} width="50%" />
        }
      </h2>
      {!loading
        ? <div className="features-list">
            <DisplayFullFeatures {...data!} />
          </div>
        : <Skeleton containerClassName="features-list" className="a-feature" count={15} inline={true} />
      }
    </div>);

}

export default FullFeatures;
