import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  IconBrandFacebook,
  IconBrandMessenger,
  IconBrandTwitter,
  IconBrandWhatsapp,
  IconCheck,
  IconCopy,
  IconSend
} from "@tabler/icons-react";
import { useLocaleUrl } from "@ct-react/locale";
import { useAppConfig } from "@ct-react/core";
import { ResumeBookChoice } from "../../models/article";
import BookChoiceResume from "./booking/book-choice-resume";
import "./sharing.scss";

const isMobileOrTablet = () => /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);

const Sharing = (props: ResumeBookChoice) => {

  const intl = useIntl();
  const { options: { blockSharing }, baseUrl } = useAppConfig();
  const { currentUrl: url } = useLocaleUrl(baseUrl);

  const [copySuccess, setCopySuccess ] = useState<boolean>(false);

  // dom interactions

  const onCopy = () => navigator.clipboard.writeText(url).then(() => {
    if (copySuccess) return;
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2500)
  });

  const onEmailClick = () => window.location.href = `mailto:?body=${encodeURIComponent(url)}`;
  const onMessagesClick = () => window.location.href = `sms:?body=${encodeURIComponent(url)}`;
  const onWhatsAppClick = () => window.open(`https://${isMobileOrTablet() ? "api" : "web"}.whatsapp.com/send?text=${encodeURIComponent(url)}`, "_blank", "width=550,height=400");
  const onFacebookClick = () => window.open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`, "_blank", "width=550,height=400");
  const onTwitterClick = () => window.open(`https://twitter.com/share?url=${encodeURIComponent(url)}&text=${"Rentalp - " + props.title}`, "_black", "width=550,height=400");


  // rendering

  return <section className="rla-sharing">

    <BookChoiceResume {...props} />

    <div className="action-group">
      <button type="button" onClick={onCopy} disabled={blockSharing}>
        {intl.formatMessage({
          id: "sharing-copy-button",
          defaultMessage: "Copier le lien"
        })}
        {copySuccess &&
          <div className="copy-success">
            <IconCheck />
            {intl.formatMessage({
              id: "sharing-copy-succeeded",
              defaultMessage: "Lien copié!"
            })}
          </div>
        }
        <IconCopy />
      </button>
    </div>

    <div className="action-group">
      <button type="button" onClick={onEmailClick} disabled={blockSharing}>
        {intl.formatMessage({
          id: "sharing-email-button",
          defaultMessage: "Adresse e-mail"
        })}
        <IconSend />
      </button>
      <button type="button" onClick={onMessagesClick} disabled={blockSharing}>
        SMS
        <IconBrandMessenger />
      </button>
      <button type="button" onClick={onWhatsAppClick} disabled={blockSharing}>
        WhatsApp
        <IconBrandWhatsapp />
      </button>
      <button type="button" onClick={onFacebookClick} disabled={blockSharing}>
        Facebook
        <IconBrandFacebook />
      </button>
      <button type="button" onClick={onTwitterClick} disabled={blockSharing}>
        Twitter
        <IconBrandTwitter />
      </button>
    </div>

  </section>;

}

export default Sharing;
