import { gql } from "@apollo/client";
import { CART_ITEM_FRAGMENT } from "@shared/gql/fragments";

export const CART_BADGE_GQL_DATA = gql`
  query CartBadge($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) { cartId itemsCount }
  }
`;

export const FULL_CART_GQL_DATA = gql`
  ${CART_ITEM_FRAGMENT}
  query UserCart($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) {
      cartId
      items {
        ... on RentalAccommodationCartItem { ...CartItemFields }
      }
      fullPrice { amount discount }
    }
  }
`;
