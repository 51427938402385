import React, { createRef } from "react";
import { IconWorld } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { LangLink, useLocaleContext } from "@ct-react/locale";
import { allLocales } from "@shared/locales";
import Dropdown, { InnerDropdown } from "../../common/dropdown";
import "./common.scss";

export const LangMenu = () => {

  const { locale } = useLocaleContext();
  const dropdownRef = createRef<any>();

  return(
    <Dropdown ref={dropdownRef}>

      <InnerDropdown.Trigger as="button" className="main-nav-item"><IconWorld/></InnerDropdown.Trigger>

      <InnerDropdown.Content className="main-nav-drop">
        <ul className="chipped">
          {allLocales.map(l =>
            <li key={l.code} className={classNames({active: locale.code === l.code})}>
              <LangLink locale={l} onClick={() => dropdownRef.current.close()}>{l.label}</LangLink>
            </li>
          )}
        </ul>
      </InnerDropdown.Content>

    </Dropdown>);

}

export default LangMenu;
