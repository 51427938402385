import React, {
  createContext,
  ElementType,
  forwardRef,
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  useContext,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
} from "@floating-ui/react";
import { IconX } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { Button } from "./minimals";
import "./modal.scss";

type ModalConfigProps = {
  size?: "sm" | "md" | "lg";
  fullscreen?: boolean;
  closableCross?: boolean;
}

type ExternalOpenState = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

type ModalProps = Required<PropsWithChildren> & ModalConfigProps & ExternalOpenState;
type ModalTriggerProps = HTMLProps<HTMLElement> & { as?: ElementType };
type ModalContentProps = HTMLProps<HTMLElement> & { as?: ElementType, title?: string, footer?: ReactNode };
export type ModalHandle = {
  scrollToAnchor: (anchorName: string) => void;
}

const useFloatingModal = (config: Required<ModalConfigProps>,
                          externalOpen?: boolean,
                          externalSetOpen?: (open: boolean) => void) => {

  const [ uncontrolledOpen, setUncontrolledOpen ] = useState(false);
  const open = externalOpen ?? uncontrolledOpen;
  const setOpen = externalSetOpen ?? setUncontrolledOpen;

  const floating = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate
  });

  const interactions = useInteractions([
    useClick(floating.context),
    useDismiss(floating.context, {
      referencePressEvent: "click",
      outsidePress: e => !(e.target as HTMLElement).classList.contains("to-modal"),
      outsidePressEvent: "click",
      ...(!config.closableCross) && {
        escapeKey: false,
        referencePress: false,
        outsidePress: false
      }
    })
  ]);

  return useMemo(() => ({ config, open, setOpen, floating, interactions }), [ config, open, floating, interactions ]);

}

const ModalContext = createContext<ReturnType<typeof useFloatingModal> | undefined>(undefined);

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) throw new Error("Modal component must be wrapped by modal context");
  return context;
}

const ModalTrigger = (
  {
    as: Tag = "span",
    className,
    children,
    ...props
  }: ModalTriggerProps) => {

  const context = useModalContext();
  const ref = useMergeRefs([ context.floating.reference, (children as any).ref ]);
  const wrapperClasses = classNames("r-modal-trigger", className);

  return (
    <Tag ref={ref}
         {...context.interactions.getReferenceProps(props)}
         className={wrapperClasses}>{children}</Tag>);

}

const ModalContent = (
  {
    as: Tag = "section",
    title,
    footer,
    children,
    className,
    style,
    ...props
  }: ModalContentProps) => {

  const { config, open, setOpen, floating, interactions } = useModalContext();
  const wrapperClasses = classNames("r-modal", className, config.size, { full: config.fullscreen });

  return (
    <FloatingPortal>
      {open &&
        // @ts-ignore
        <FloatingOverlay className="r-modal-overlay" lockScroll={true}>
          <FloatingFocusManager context={floating.context} initialFocus={-1}>
            <Tag ref={floating.floating}
                 {...interactions.getFloatingProps(props)}
                 className={wrapperClasses}>

              <div className="r-modal-header">
                {!!title && <h1>{title}</h1>}
                {config.closableCross &&
                  <Button type="button"
                          tabIndex={-1}
                          className="r-modal-close rounded-icon link"
                          onClick={() => setOpen(false)}><IconX /></Button>
                }
              </div>
              <div className="r-modal-content">{children}</div>
              {!!footer && <div className="r-modal-footer">{footer}</div>}

            </Tag>
          </FloatingFocusManager>
        </FloatingOverlay>
      }
    </FloatingPortal>);
}

const Modal = forwardRef<ModalHandle, ModalProps>((
  {
    size = "md",
    fullscreen = false,
    closableCross = true,
    children,
    open: externalOpen,
    onOpenChange: externalSetOpen,
  }: ModalProps, forwardedRef) => {

  const floating = useFloatingModal({ size, fullscreen, closableCross }, externalOpen, externalSetOpen);

  useImperativeHandle(forwardedRef, () => ({
    scrollToAnchor: anchorName => {
      setTimeout(() => document.getElementById(anchorName)?.scrollIntoView({ behavior: "smooth" }), 250);
    }
  }));

  return (<ModalContext.Provider value={floating}>{children}</ModalContext.Provider>);

});

export default Modal;

export namespace InnerModal {
  export const Trigger = ModalTrigger;
  export const Content = ModalContent;
}
