import React, { useState } from "react";
import { useIntl } from "react-intl";
import { IconMenu2, IconX } from "@tabler/icons-react";
import {
  FloatingOverlay,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";
import { LocaleLink } from "@ct-react/locale";
import { useDisplayMobileOnly } from "../../../tools/breakpoints";
import { Button } from "../../common/minimals";
import "./internal-menu.scss";

const MenuList = ({ onClick = () => void 0 }: { onClick?: () => void }) => {

  const intl = useIntl();

  return (
    <ul className="rlh-menu-items">
      <li>
        <LocaleLink to="/agencies"
                    onClick={onClick}>
          {intl.formatMessage({ id: "agencies", defaultMessage: "Agences" })}
        </LocaleLink>
      </li>
      <li>
        <LocaleLink to="/resorts"
                    onClick={onClick}>
          {intl.formatMessage({ id: "resorts", defaultMessage: "Stations" })}
        </LocaleLink>
      </li>
      <li>
        <LocaleLink to="/about"
                    onClick={onClick}>
          {intl.formatMessage({ id: "about", defaultMessage: "À propos" })}
        </LocaleLink>
      </li>
      <li className="buy">
        <a className="has-btn"
           href="https://www.homealp.com"
           target="_blank"
           onClick={onClick}>
          <Button className="secondary" tabIndex={-1}>
            {intl.formatMessage({ id: "to-buy", defaultMessage: "Acheter" })}
          </Button>
        </a>
      </li>
    </ul>);

};

const InternalMenu = () => {

  const isMobile = useDisplayMobileOnly();

  // floating ui

  const [ menuOpen, setMenuOpen ] = useState<boolean>(false);

  const { reference, floating, context } = useFloating({
    open: menuOpen,
    onOpenChange: setMenuOpen,
    strategy: "fixed",
    placement: "bottom",
    middleware: [ offset(6) ]
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context)
  ]);

  // rendering

  if (isMobile)
    return (
      <>
        <button ref={reference}
                {...getReferenceProps()}
                className="mb-menu main-nav-item">
          {menuOpen ? <IconX /> : <IconMenu2 />}
        </button>
        <FloatingPortal>
          {menuOpen &&
            // @ts-ignore
            <FloatingOverlay className="mb-menu-overlay"
                             lockScroll={true}>
              <div ref={floating}
                   {...getFloatingProps()}
                   style={{ height: "100%" }}>
                <MenuList onClick={() => setMenuOpen(false)} />
              </div>
            </FloatingOverlay>
          }
        </FloatingPortal>
      </>);

  return (<MenuList />);

}

export default InternalMenu;
