import { useMemo } from "react";
import isoCountries from "i18n-iso-countries";
import { useLocaleContext } from "@ct-react/locale";

export const useCountriesList = () => {

  const { locale } = useLocaleContext();

  return useMemo(() => Object.fromEntries(Object
    .entries(isoCountries.getNames(locale.basename, { select: "official" }))
    .sort((a, b) => a[1].localeCompare(b[1]))), [ locale ]);

}
