import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useLocaleNavigate } from "@ct-react/locale";
import { useCartBadge } from "../../hooks/cart-step-check";
import { useLazyLocalFetcher } from "../../hooks/local-fetcher";
import { User } from "@shared/models/user";
import { CartCustomer } from "../../models/cart";
import { CartContext } from "./cart-outlet";
import PaymentInformationForm from "../../components/forms/payment-information";
import "./common.scss";

const CartInformation = () => {

  const intl = useIntl();
  const navigate = useLocaleNavigate();
  const { state } = useLocation();

  const itemsCount = useCartBadge();
  const { userId, changeIndex } = useOutletContext<CartContext>();

  const [ loading, setLoading ] = useState<boolean>(true);
  const [ editVisitorInfo, setEditVisitorInfo ] = useState<CartCustomer>(state?.visitorInfo);
  const [ visitorInfo, setVisitorInfo ] = useState<CartCustomer | undefined>(undefined);

  const [ getUserProfile ] = useLazyLocalFetcher<User>("user");

  useEffect(() => {
    if (!userId || !!state?.visitorInfo) return;
    const fetchUser = async () => await getUserProfile();
    fetchUser().then(profile => {
      const { address, ...others } = profile;
      setEditVisitorInfo({ ...others, address } as CartCustomer);
    });
  }, [ userId ]);

  useEffect(() => {
    itemsCount === 0 && navigate("/cart", { state });
    (!userId && !state?.chosenVisitorType) && navigate("/cart/account");
    setLoading(false);
    changeIndex(2);
  }, []);

  const onFormChange = (value: CartCustomer, isValid: boolean) => {
    if (!isValid) return;
    setVisitorInfo(value);
  }

  // rendering

  if (loading)
    return (<Skeleton className="cart-skeleton" />);

  return (
    <div className="cart-item-wrapper">

      <PaymentInformationForm className="rl-two-col"
                              data={editVisitorInfo}
                              onChange={onFormChange} />

      <div className="rl-two-col stepper-actions">
        <button type="button"
                className="step-btn back"
                onClick={() => navigate("/cart", { state })}>
          {intl.formatMessage({
            id: "cart-process-back-cart-button",
            defaultMessage: "Modifier votre réservation"
          })}
        </button>
        <button type="button"
                className="step-btn"
                onClick={() => navigate("/cart/payment", { state: { ...state, ...(!!userId) && { chosenVisitorType: true }, visitorInfo } })}
                disabled={!visitorInfo}>
          {intl.formatMessage({
            id: "cart-process-next-payment-button",
            defaultMessage: "Définir le modèle de paiement"
          })}
        </button>
      </div>

    </div>);

};

export default CartInformation;
