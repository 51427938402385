import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IconInfoSquareRoundedFilled } from "@tabler/icons-react";
import { castPrice, classNames } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { CartPaymentAmount, CartPaymentMode } from "@ct-react/calendar";
import { bookingTranslations } from "../../i18n/sharable-defs";
import { DataProps } from "../../tools/components";
import {
  CartItemDownPaymentConfig,
  CartItemPrice as CartItemPriceModel,
  CartPrice as CartPriceModel
} from "../../models/cart";
import Tooltip, { InnerTooltip } from "../common/tooltip";
import "./cart-price.scss";

const transDefs = defineMessages({
  title: { id: "cart-item-price-title", defaultMessage: "Détails du prix" },
  chargeTitle: { id: "chart-item-charge-title", defaultMessage: "Frais de paiement" },
  resumeTitle: { id: "chart-item-resume-title", defaultMessage: "Réservation" },
  paymentTitle: { id: "chart-item-payment-title", defaultMessage: "Paiement immédiat" },
  unitLabel: { id: "cart-item-price-unit", defaultMessage: "Prix unitaire" },
  discountLabel: { id: "cart-item-price-discount", defaultMessage: "Réduction" },
  downLabel: { id: "cart-item-price-down", defaultMessage: "Acompte" },
  totalLabel: { id: "cart-item-price-total", defaultMessage: "Total" },
  amountLabel: { id: "cart-item-price-amount", defaultMessage: "Montant" }
});

type CartPaymentResultProps = {
  cartPrice: CartPriceModel;
  mode: CartPaymentMode;
  detail?: CartPaymentAmount;
}

const CartItemPrice = ({ data }: DataProps<CartItemPriceModel>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const benefits = useMemo(() => {
    const options = data.options.map((opt: any) => castPrice(opt.amount));
    return options.length === 0 ? null :
      { amount: options.reduce((acc, cur) => acc + cur.amount, 0), currency: options[0].currency };
  }, [ data ]);

  return <div className="cart-price-detail">
    <h2>{intl.formatMessage(transDefs.title)}</h2>
    <dl className="price-list">
      <dt>{intl.formatMessage(transDefs.unitLabel)}</dt>
      <dd>{print.price(data.bare)}</dd>
      {!!data.discount &&
        <>
          <dt>{intl.formatMessage(transDefs.discountLabel)}</dt>
          <dd className="discount">{print.price(data.discount)}</dd>
        </>
      }
      {(!!benefits) &&
        <>
          <dt>{intl.formatMessage(bookingTranslations.benefits)}</dt>
          <dd>{print.price(benefits)}</dd>
        </>
      }
    </dl>
    <dl className="price-list total">
      <dt>{intl.formatMessage(transDefs.amountLabel)}</dt>
      <dd>{print.price(data.amount)}</dd>
    </dl>
  </div>
    ;

}

export const CartItemCharge = ({ data }: DataProps<CartItemDownPaymentConfig>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const wrapperClasses = classNames("cart-price-detail", "charge");

  return <div className={wrapperClasses}>
    <h2>{intl.formatMessage(transDefs.chargeTitle)}</h2>
    <dl className="price-list">
      <dt className="charges">
        <Tooltip placement="bottom">
          <InnerTooltip.Trigger as="span" className="tooltip-more">
            {intl.formatMessage(bookingTranslations.customerCharge)}
            <IconInfoSquareRoundedFilled />
          </InnerTooltip.Trigger>
          <InnerTooltip.Content>
            <p>{intl.formatMessage(bookingTranslations.customerChargeTooltip)}</p>
            {data.rate < 1 && <p>{intl.formatMessage(bookingTranslations.customerChargeAdaptive)}</p>}
          </InnerTooltip.Content>
        </Tooltip>
      </dt>
      <dd>{intl.formatMessage(bookingTranslations.customerChargePrice, {
        adaptive: data.rate < 1,
        amount: print.price(data.providerCharge!)
      })}</dd>

    </dl>
  </div>
}


export const CartPrice = ({ data }: DataProps<CartPriceModel>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  return (
    <div className="cart-price-detail">
      <h2>Total</h2>
      <dl className="price-list" />
      <dl className="price-list total">
        <dt>{intl.formatMessage(transDefs.amountLabel)}</dt>
        <dd>{print.price(data.amount)}</dd>
      </dl>
    </div>);

};

export const CartPaymentResult = ({ cartPrice, mode, detail }: CartPaymentResultProps) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  return (
    <div className="cart-payment-resume">
      <div className="cart-price-detail">
        <h2>{intl.formatMessage(transDefs.resumeTitle)}</h2>
        <dl className="price-list" />
        <dl className="price-list total">
          <dt>{intl.formatMessage(transDefs.amountLabel)}</dt>
          <dd>{print.price(cartPrice.amount)}</dd>
        </dl>
      </div>
      {!!detail &&
        <div className="cart-price-detail">
          <h2>{intl.formatMessage(transDefs.paymentTitle)}</h2>
          <dl className="price-list">
            <dt>{intl.formatMessage(mode === "full" ? transDefs.totalLabel : transDefs.downLabel)}</dt>
            <dd>{print.price(detail.detailed.booking)}</dd>
            {!!detail.detailed.providerCharge &&
              <>
                <dt className="charges">
                  <Tooltip placement="bottom">
                    <InnerTooltip.Trigger as="span" className="tooltip-more">
                      {intl.formatMessage(bookingTranslations.customerCharge)}
                      <IconInfoSquareRoundedFilled />
                    </InnerTooltip.Trigger>
                    <InnerTooltip.Content>
                      <p>{intl.formatMessage(bookingTranslations.customerChargeTooltip)}</p>
                    </InnerTooltip.Content>
                  </Tooltip>
                </dt>
                <dd>{print.price(detail.detailed.providerCharge)}</dd>
              </>
            }
          </dl>
          <dl className="price-list total">
            <dt>{intl.formatMessage(transDefs.amountLabel)}</dt>
            <dd>{print.price(detail.total)}</dd>
          </dl>
        </div>
      }
    </div>);

}

export default CartItemPrice;
